import { stubTrue } from "lodash";

export const ColumnChartDataForQuestionType = async (
  series,
  categories,
  stacked,
  enabled,
) => {

  const onSelect = (data) => {
    const questionIds = [data?.questionId];
    localStorage.setItem('questionIds', questionIds);
    window.location.href = '/#/sector_questions';

  };
  const allZeroes = series.every(series => series.data.every(value => value === 0));

  let chartSeries;
  if (allZeroes) {
    chartSeries = series.map(({ name }) => ({
      name: name,
      data: [1]
    }));
  } else {
    chartSeries = series;
  }
  const chartData = {
    series: chartSeries,
    options: {
      ...(allZeroes ? { colors: ['#e9ecef'] } : {}),
      chart: {
        type: allZeroes ? "line" : "bar",
        height: 350,
        stacked: allZeroes ? false : stacked,
        events: {
          dataPointSelection: (event, chartContext, config) => {
            const seriesIndex = config.seriesIndex;
            onSelect(series[seriesIndex]);
          },
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      dataLabels: {
        enabled: allZeroes ? false: enabled,    
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      xaxis: {
        categories: categories,
      },
      fill: {
        opacity: 1,
      },

      yaxis: {
        title: {
          text: "Reading Value",
        },
      },
      fill: {
        opacity: 1,
      },
      // chart: {
      //   toolbar: {
      //     tools: {
      //       download: false
      //     }
      //   }
      // },
      tooltip: {
        y: { 
          formatter: function (val) {
            return val + " Litre";
          },
        },
        x: { show: false },
        enabled: allZeroes ? false: true, 
      },
      legend: {
        show: true,
        position: "bottom",
        horizontalAlign: "left",
      },
    },
  };
  // chartData.downloadCSV = () => {
  //   // Extract keys from the first data point to use as column headers
  //   const headers = Object.keys(series[0]);
  //   // Generate CSV content
  //   const csvContent = [headers.join(',')];
  //   series.forEach((dataPoint) => {
  //     const values = headers.map((key) => dataPoint[key]);
  //     csvContent.push(values.join(','));
  //   });
  //   // Create a Blob containing the CSV data
  //   const blob = new Blob([csvContent.join('\n')], { type: 'text/csv' });

  //   // Create a link element to trigger the download
  //   const link = document.createElement('a');
  //   link.href = window.URL.createObjectURL(blob);
  //   link.download = 'chart_data.csv';

  //   // Dispatch a click event on the link to trigger the download
  //   link.click();
  // };
  return chartData;
};






export const RadioBarChartDataForDisclosure = async (level, series) => {
  const chartData = {
    series: series,
    options: {
      chart: {
        height: 800,
        type: "radialBar",
      },
      // title: {
      //   text: "Radio Bar View",
      // },
      plotOptions: {
        radialBar: {
          dataLabels: {
            name: {
              fontSize: "22px",
            },
            value: {
              fontSize: "16px",
            },
            total: {
              show: true,
              label: "Total",
              formatter: function (w) {
                return (
                  (w.globals.series.reduce((a, b) => a + b, 0) / 4).toFixed(2) +
                  "%"
                );
              },
            },
          },
        },
      },
      labels: level,
    },
  };
  return chartData;
};
export const RadioBarChartDataForCompany = async (level, series) => {
  const chartData = {
    series: series,
    options: {
      chart: {
        height: 800,
        type: "radialBar",
      },
      // title: {
      //   text: "Radio Bar View",
      // },
      plotOptions: {
        radialBar: {
          dataLabels: {
            name: {
              fontSize: "22px",
            },
            value: {
              fontSize: "16px",
            },
            total: {
              show: true,
              label: "Total",
              formatter: function (w) {
                return (
                  (w.globals.series.reduce((a, b) => a + b, 0) / 4).toFixed(2) +
                  "%"
                );
              },
            },
          },
        },
      },
      labels: level,
    },
  };
  return chartData;
};
export const RadioBarChartData = async (level, series) => {
  const chartData = {
    series: series,
    options: {
      chart: {
        height: 800,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          dataLabels: {
            name: {
              fontSize: "22px",
            },
            value: {
              fontSize: "16px",
            },
            total: {
              show: true,
              label: "Total",
              formatter: function (w) {
                return (
                  (w.globals.series.reduce((a, b) => a + b, 0) / 1).toFixed(2) +
                  "%"
                );
              },
            },
          },
        },
      },
      labels: level,
      legend: {
        show: true,
        position: "bottom",
        horizontalAlign: "left",
      },
    },
  };
  return chartData;
};

export const ColumnChartData = async (level, series) => {
  const chartData = {
    series: series,
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: level,
      },
      yaxis: {
        title: {
          text: "% (Percentage)",
        },
      },
      fill: {
        opacity: 1,
      },
      // title: {
      //   text: "Column View",
      // },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + "%";
          },
        },
      },
    },
  };
  return chartData;
};
export const ColumnChartDataForSource = async (teamWorkloadResults, series) => {
  const chartData = {
    series: series,
    options: {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      dataLabels: {
        enabled: false, // Disable data labels
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
          dataLabels: {
            enabled: false,
          },
        },
      },
      xaxis: {
        categories: teamWorkloadResults.map(
          (user) => `${user?.firstName} ${user?.lastName}`
        ),
      },
      yaxis: {
        labels: {
          formatter: (value) => `${value}%`, // Display percentages on the y-axis
        },
      },
      fill: {
        opacity: 1,
      },
      legend: {
        position: "bottom",
        offsetY: 40,
      },
      tooltip: {
        y: {
          formatter: (value) => `${value}%`, // Display percentages in the tooltip
        },
      },
    },
  };

  return chartData;
};


export const BarChartDataForQuestionType = async (series, categories) => {
  const chartData = {
    series: series,
    options: {
      chart: {
        type: "bar",
        height: 430,
        stacked: true,
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetX: -6,
        style: {
          fontSize: "12px",
          colors: ["#fff"],
        },
      },
      stroke: {
        show: true,
        width: 1,
        colors: ["#fff"],
      },
      tooltip: {
        shared: true,
        intersect: false,
      },
      xaxis: {
        categories: categories,
      },
      yaxis: {
        title: {
          text: "Reading Value",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + " Litre";
          },
        },
        x: { show: false },
      },
    },
  };
  return chartData;
};
export const BarChartDataForSource = async (
  teamWorkloadResults,
  filteredSeries
) => {
  const chartData = {
    series: filteredSeries,
    options: {
      chart: {
        type: "bar",
        height: 500,
        stacked: true,
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            total: {
              enabled: true,
              offsetX: 0,
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
          },
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },

      xaxis: {
        categories: teamWorkloadResults.map(
          (user) => `${user?.firstName} ${user?.lastName}`
        ),
      },
      yaxis: {
        labels: {
          formatter: (value) => `${value}`,
        },
      },

      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
      fill: {
        opacity: 1,
      },
      legend: {
        position: "top",
        horizontalAlign: "left",
        offsetX: 40,
      },
    },
  };

  return chartData;
};

export const BarChartData = async (level, series) => {
  const chartData = {
    series: series,
    options: {
      chart: {
        type: "bar",
        height: 500,
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetX: -6,
        style: {
          fontSize: "12px",
          colors: ["#fff"],
        },
      },
      // title: {
      //   text: "Bar View",
      // },
      stroke: {
        show: true,
        width: 1,
        colors: ["#fff"],
      },
      tooltip: {
        shared: true,
        intersect: false,
      },
      xaxis: {
        categories: level,
        title: {
          text: "% (Percentage)",
        },
      },
    },
  };
  return chartData;
};
export const FunnelChartData = async (level, series) => {
  const chartData = {
    series: series,
    options: {
      chart: {
        type: "bar",
        height: 500,
      },
      plotOptions: {
        bar: {
          borderRadius: 0,
          horizontal: true,
          distributed: true,
          barHeight: "80%",
          isFunnel: true,
        },
      },
      colors: [
        "#F44F5E",
        "#E55A89",
        "#D863B1",
        "#CA6CD8",
        "#B57BED",
        "#8D95EB",
        "#62ACEA",
        "#4BC3E6",
      ],
      dataLabels: {
        enabled: true,
        formatter: function (val, opt) {
          return opt.w.globals.labels[opt.dataPointIndex] + " " + val + "%";
        },
        dropShadow: {
          enabled: true,
        },
      },
      // title: {
      //   text: "Pyramid View",
      //   align: "left",
      // },
      xaxis: {
        categories: level,
        title: {
          text: "% (Percentage)",
        },
      },
      legend: {
        show: false,
      },
    },
  };
  return chartData;
};
export const RadarChartsData = async (level, series) => {
  const chartData = {
    series: series,
    options: {
      chart: {
        height: 1000,
        type: "radar",
        dropShadow: {
          enabled: true,
          blur: 1,
          left: 1,
          top: 1,
        },
      },
      // title: {
      //   text: "Radar View",
      // },
      stroke: {
        width: 2,
      },
      fill: {
        opacity: 0.1,
      },
      markers: {
        size: 0,
      },
      xaxis: {
        categories: level,
        title: {
          text: "% (Percentage)",
        },
      },
    },
  };
  return chartData;
};
export const PolarAreaChartsData = async (level, series) => {
  const chartData = {
    series: series,
    options: {
      chart: {
        type: "polarArea",
      },
      stroke: {
        colors: ["#fff"],
      },
      fill: {
        opacity: 0.8,
      },
      // title: {
      //   text: "Polar View",
      // },
      responsive: [
        {
          breakpoint: 1180,
          options: {
            chart: {
              width: 500,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      labels: level,
    },
  };
  return chartData;
};
