import React, { useEffect, useState } from "react";
import { apiCall } from "../../../_services/apiCall";
import config from "../../../config/config.json";
import AssignQuestions from "../../Company Sub Admin/Component/Sector Questions/AssignQuestions";
import swal from "sweetalert";
import "./AuditDetails.css";
import Header from "../../header/header";
import Sidebar from "../../sidebar/sidebar";
import { NavLink, useLocation } from "react-router-dom";
import QuestionTypeTabSection from "../../Company Sub Admin/Component/Sector Questions/QuestionTypeTabSection";
import AuditListingFilter from "../../Company Sub Admin/Component/Sector Questions/Filter/AuditListingFilter";
import Maximize from "../../../img/sector/maximize.png";
import Minimize from "../../../img/sector/minimize.png";
import AuditCard from "./AuditCard";
import Loader from "../../loader/Loader";
import NoDataFound from "../../../img/no_data_found.png"
// import { authenticationService } from "../../../_services/authentication.service";
import AuditAnswers from "./AuditAnswers";
import { Card, Col, Row, Table, Spinner, Modal, Button } from "react-bootstrap";

const AuditList = ({ listing }) => {
  const [audit_Data, setAudit_Data] = useState([]);
  const [filterAuditData, setFilterAuditData] = useState([]);
  const location = useLocation();
  const [leftWidth, setLeftWidth] = useState(6);
  const [rightWidth, setRightWidth] = useState(6);
  const [hideCol, setHideCol] = useState(false);
  const [selectedFinancialYear, setSelectedFinancialYear] = useState();
  const [selectedSupplier, setSelectedSupplier] = useState();
  const [status, setStatus] = useState([]);
  const [update, setUpdate] = useState([]);
  const [questionnaire, setQuestionnaire] = useState([]);
  const [selectedQuestionType, setSelectedQuestionType] = useState("All");
  const [selectedQuestionIds, setSelectedQuestionIds] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [hideAnswerCol, setHideAnswerCol] = useState(false);
  const [auditAnswer, setAuditAnswer] = useState();
  const [assignedList, setAssignedList] = useState([]);
  const [selectedRow, setSelectedRow] = useState(false);
  const [processList, setProcessList] = useState([]);
  const [menuList, setMenuList] = useState([]);
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [url, setUrl] = useState("");
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const findQuestionIds = (str) => {
    let commaSeparated = str?.split(",") ?? str.trim();

    let questionIds = [];
    let hyphenatedRange = [];

    commaSeparated?.map((data) => {
      data = data.toString().trim();
      if (data.toString().includes("-")) hyphenatedRange.push(data);
      else questionIds.push(parseInt(data));
    });

    hyphenatedRange.map((range) => {
      range = range.split("-");
      let start = range[0];
      let end = range[1];

      for (let i = start; i <= end; i++) {
        questionIds.push(parseInt(i));
      }
    });

    setSelectedQuestionIds(questionIds);
  };
  useEffect(() => {
    if (!filterAuditData?.length) {
      setLeftWidth(12);
      setRightWidth(0);
      setHideAnswerCol(true);
    } else {
      setLeftWidth(6);
      setRightWidth(6);
      setHideAnswerCol(false);
    }
  }, [filterAuditData]);
  // useEffect(() => {
  //   setIsLoading(true);
  //   (async () => {
  //     const { isSuccess, data, error } = await apiCall(
  //       `${config.API_URL}getAuditHistoryListing`,
  //       {},
  //       {
  //         question_id: selectedQuestionIds ? `[${selectedQuestionIds}]` : [],
  //         questionType: selectedQuestionType ? selectedQuestionType : "",
  //         questionnaire_type: questionnaire ? `[${questionnaire}]` : [],
  //         update_type: update ? `[${update}]` : [],
  //         audit_status: status ? `[${status}]` : [],
  //         supplier_company_id: selectedSupplier ? selectedSupplier : "",
  //         financial_year: selectedFinancialYear ? selectedFinancialYear : "",
  //         muduleType: "AUDIT",
  //       }
  //     );

  //     if (isSuccess) {
  //       setAudit_Data(data?.data);
  //       setIsLoading(false);
  //     }
  //     if (error) {
  //       setAudit_Data(data);
  //       setIsLoading(false);
  //       swal({
  //         icon: "error",
  //         title: data?.message,
  //         timer: 1000,
  //       });
  //     }
  //   })();
  // }, [
  //   listing,
  //   selectedFinancialYear,
  //   selectedSupplier,
  //   status,
  //   update,
  //   questionnaire,
  //   selectedQuestionType,
  //   selectedQuestionIds,
  // ]);

  // useEffect(() => {
  //   getProcessIdData();
  // }, []);

  // const getProcessIdData = () => {
  //   axios
  //     .get(
  //       `${config.API_URL}getProcessId?company_id=${localStorage.getItem(
  //         "user_temp_id"
  //       )}&current_role=${localStorage.getItem("role")}`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("token")}`,
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     )
  //     .then((response) => {
  //       const data = response?.data?.data;

  //       setProcessList(data);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };
  const answerWidth = () => {
    setLeftWidth(0);
    setRightWidth(12);
    setHideCol(true);
  };

  const questionWidth = () => {
    setLeftWidth(6);
    setRightWidth(6);
    setHideCol(false);
  };

  const getAuditListing = async () => {
    const { isSuccess, data, error } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getAuditListing`,
      {},
      {
        topicIds: [],
        kpiIds: [],
        question_id: selectedQuestionIds ? `[${selectedQuestionIds}]` : [],
        questionType: selectedQuestionType ? selectedQuestionType : "",
        questionnaire_type: questionnaire ? `[${questionnaire}]` : [],
        update_type: update ? `[${update}]` : [],
        audit_status: status ? `[${status}]` : [],
        supplier_company_id: selectedSupplier ? selectedSupplier : "",
        financial_year: selectedFinancialYear ? selectedFinancialYear : "",
        muduleType: "AUDIT",
      }
    );

    if (isSuccess) {
      setAudit_Data(data?.data);
      setFilterAuditData(data?.data);
      setAssignedList(data?.getAssignedDetails);
      setIsLoading(false);
      getSource();
    }
    if (error) {
      setAudit_Data(data);
      setIsLoading(false);
      swal({
        icon: "error",
        title: data?.message,
        timer: 1000,
      });
    }
  };
  const getSource = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getSource`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      const locationArray = data?.data?.reverse().map((item) => ({
        id: item.id,
        location: `${item?.location?.area}, ${item?.location?.city}, ${item?.location?.state}, ${item?.location?.country}, ${item?.location?.zipCode}`,
      }));
      setProcessList(locationArray);
    }
  };

  const handleSubmit = async (questionId, answerId, remark, financialYearId, validation, questionType) => {
    const { isSuccess, error, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}validateAnswers`,
      {},
      {
        questionId: questionId,
        answerId: answerId,
        questionType: questionType,
        remark: remark,
        validation: validation,
        financialYearId: financialYearId,
      },
      "POST"
    );

    if (isSuccess) {
      getAuditListing();
    }

    if (error) {
      swal({
        icon: "error",
        title: data.message,
        timer: 1000,
      });
    }
  };
  useEffect(() => {
    const filteredArray = audit_Data.filter(obj => obj.questionType === selectedQuestionType);
    setFilterAuditData(filteredArray);
  }, [selectedQuestionType]);

  useEffect(() => {

    getAuditListing();
    // const currentUser = authenticationService?.currentUserSubject?.getValue();
    const settingsMenu = JSON.parse(localStorage.getItem("menu")).find(
      (item) => item?.url === "sector_questions"
    );
    setMenuList(settingsMenu?.permissions);
  }, []);
  return (
    <>
      <Sidebar dataFromParent={location?.pathname} />
      <Header />
      <div className="main_wrapper">
        <section className="inner_wraapper">
          <div className="hol_rell">
            <div className="steps-form">
              <div className="steps-row setup-panel">
                <div className="tabs-top setting_admin my-0">
                  <ul>
                    <li>
                      <NavLink to="/audit-listing" className="activee"> Audit Listing </NavLink>
                    </li>
                    <li>
                      <NavLink to="/audit-history"> Audit History </NavLink>
                    </li>
                  </ul>
                </div>
                <div className="hstack gap-2 mx-2">
                  <div className="filter_ICOn">
                    <AuditListingFilter
                      setSelectedFinancialYear={setSelectedFinancialYear}
                      setSelectedSupplier={setSelectedSupplier}
                      status={status}
                      setStatus={setStatus}
                      update={update}
                      setUpdate={setUpdate}
                      questionnaire={questionnaire}
                      setQuestionnaire={setQuestionnaire}
                      findQuestionIds={findQuestionIds}
                    />
                  </div>
                  <Button variant="info" onClick={handleShow}>
                    Assign Question
                  </Button>
                  {show === true && (
                    <AssignQuestions
                      questions={filterAuditData}
                      financialYearId={6}
                      fetchquestionApi={"fetchquestionApi"}
                      entity="company"
                      show={show}
                      onHide={handleClose}
                      tabType="AUDIT"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="Introduction">
            <div className="question_section">
              <div
                className="d-flex align-items-center justify-content-between"
                style={{
                  background: "#1f9ed1",
                  borderBottom: "3px solid #fff",
                }}
              >
                <QuestionTypeTabSection
                  setSelectedQuestionType={setSelectedQuestionType}
                />
                <div className="resize__tabel">
                  {hideCol === false ? (
                    <img
                      className="mx-2"
                      src={Maximize}
                      alt="Maximize"
                      title="Maximize"
                      onClick={() => answerWidth()}
                    />
                  ) : (
                    <img
                      className="mx-2"
                      src={Minimize}
                      alt="Minimize"
                      title="Minimize"
                      onClick={() => questionWidth()}
                    />
                  )}
                </div>
              </div>
              {isLoading ? (
                <Loader />
              ) : (
                <Row>
                  <Col
                    className="Question__type"
                    md={leftWidth}
                    hidden={hideCol}
                  >
                    {filterAuditData?.length ? (
                      <Table
                        striped
                        hover
                        bordered
                        className="m-0"
                        style={{ cursor: "pointer" }}
                      >
                        <thead>
                          <tr className="fixed_tr_section">
                            <td style={{ width: 55 }}>Sr No</td>
                            <td>Question</td>
                            <td>Status</td>
                          </tr>
                        </thead>
                        <tbody>
                          {filterAuditData?.map((audit_data, index) => (
                            <AuditCard
                              audit_data={audit_data}
                              answerWidth={answerWidth}
                              questionWidth={questionWidth}
                              hideCol={hideCol}
                              index={index + 1}
                              setAuditAnswer={(data) => {
                                setAuditAnswer(data);
                              }}
                              selectedRow={selectedRow}
                              setUrl={setUrl}
                            />
                          ))}
                        </tbody>
                      </Table>
                    ) : (
                      <div className="hstack justify-content-center">
                        <img src={NoDataFound} alt="" srcset="" />
                      </div>
                    )}
                  </Col>
                  {auditAnswer && (
                    <Col md={rightWidth} hidden={hideAnswerCol}>
                      <AuditAnswers
                        assignedDeatils={assignedList}
                        auditAnswer={auditAnswer}
                        setSelectedRow={setSelectedRow}
                        processList={processList}
                        handleValidateSubmit={handleSubmit}
                        module="LISTING"
                      />
                    </Col>
                  )}
                </Row>
              )}
            </div>
          </div>
          <Modal show={showModal}>
            <div className="modal-lg">
              <Modal.Header className="justify-content-end">
                <Button
                  variant="outline-dark"
                  onClick={() => setShowModal(false)}
                >
                  <i className="fa fa-times"></i>
                </Button>
              </Modal.Header>
              <div className="modal-body">
                <img
                  src={url}
                  style={{
                    width: "100%",
                    height: "auto",
                  }}
                />
              </div>
            </div>
          </Modal>
        </section>
      </div>
    </>
  );
};

export default AuditList;
