import React, { useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Col,
  Form,
  FormGroup,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { apiCall } from "../../_services/apiCall";
import config from "../../config/config.json";
import Loader from "../loader/Loader";

const AccessManagement = (props) => {
  const { tab, userPermissionList } = props;
  console.log(userPermissionList, "AccessManagement");
  const [pemissionList, setPemissionList] = useState(null);
  const [showAddManagement, setShowAddManagement] = useState(false);
  const [roleName, setRoleName] = useState("");
  const [currentRoleId, setCurrentRoleId] = useState("");
  const [mode, setMode] = useState("create");
  const [selectedId, setSelectedId] = useState(null);
  const [managementListValue, setManagementListValue] = useState([]);
  const [filterListValue, setFilterListValue] = useState([]);
  const [updatedPermissions, setUpdatedPermissions] = useState([]);
  const [auditModule, setAuditModule] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showSkelton, setshowSkelton] = useState(false);
  const [answerAccepted, setAnswerAccepted] = useState();
  const [isError, setIsError] = useState(false);
  const [deleteAccessPopup, setDeleteAccessPopup] = useState(false);
  const closeDeleteAccessPopup = () => setDeleteAccessPopup(false);

  const showDeleteAccessPopup = (id) => {
    setSelectedId(id);
    setDeleteAccessPopup(true);
  };

  const handleShowAddManagement = () => {
    setMode("create");
    setShowAddManagement(true);
  };

  const handleEditRoleMasterShow = (id, inputValue) => {
    setMode("edit");
    setSelectedId(id);
    setRoleName(inputValue);
    setShowAddManagement(true);
  };

  const handleCloseAddManagement = () => {
    setMode("create");
    setIsError(false);
    setShowAddManagement(false);
  };

  const setRoleTitle = (e) => {
    const inputValue = e.target.value;
    const isDuplicate = managementListValue.some(
      (data) => data.role_name === inputValue
    );
    if (isDuplicate) {
      setIsError(true);
    } else {
      setRoleName(inputValue);
      setIsError(false);
    }
  };

  const createRoleMaster = async () => {
    const { isSuccess, data, error } = await apiCall(
      config.POSTLOGIN_API_URL_COMPANY + `createRoleMaster`,
      {},
      { roleName: roleName },
      "POST"
    );
    if (isSuccess) {
      roleManagementList();
      setRoleName("");
      handleCloseAddManagement();
    }
  };

  const updateRoleMaster = async (e) => {
    const { isSuccess, data, error } = await apiCall(
      config.POSTLOGIN_API_URL_COMPANY + `updateRoleMaster`,
      {},
      { roleName: roleName, id: selectedId },
      "POST"
    );
    if (isSuccess) {
      roleManagementList();
      setRoleName("");
      handleCloseAddManagement();
    }
  };
  const deleteRoleMaster = async () => {
    const { isSuccess, data, error } = await apiCall(
      config.POSTLOGIN_API_URL_COMPANY + `deleteRoleMaster`,
      {},
      { id: selectedId },
      "POST"
    );
    if (isSuccess) {
      roleManagementList();
      closeDeleteAccessPopup();
    }
  };

  const handleSearch = (searchTerm) => {
    const trimmedSearchTerm = searchTerm.trim();

    if (trimmedSearchTerm === "") {
      setFilterListValue([...managementListValue]);
    } else {
      const filteredResult = managementListValue.filter((item) =>
        item.role_name.toLowerCase().includes(trimmedSearchTerm.toLowerCase())
      );
      setFilterListValue(filteredResult);
    }
  };
  const roleManagementList = async () => {
    setshowSkelton(true);
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getMasterData`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      setshowSkelton(false);
      setManagementListValue(data?.data?.reverse());
      setFilterListValue(data?.data);
    }
  };

  // const handleCheckboxChange = async (validate) => {
  //   setAnswerAccepted(validate);
  //   const { isSuccess, data } = await apiCall(
  //     `${config.POSTLOGIN_API_URL_COMPANY}updateAcceptence`,
  //     {},
  //     { role_id: roleId, validatorAnswer: validate },
  //     "GET"
  //   );
  //   if (isSuccess) {

  //   }
  // };
  const rolePermissionList = async (roleId) => {
    setshowSkelton(true);
    setCurrentRoleId(roleId);
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getPermissionDataBasedOnRoleId`,
      {},
      { role_id: roleId },
      "GET"
    );
    if (isSuccess) {
      setshowSkelton(false);
      setPemissionList(data.data);
      setAnswerAccepted(data?.answerAccepted);
    }
  };
  const updatePermissionToRole = async () => {
    const { isSuccess, data, error } = await apiCall(
      config.POSTLOGIN_API_URL_COMPANY + `updatePermissionToRole`,
      {},
      { updatedPermissions: updatedPermissions, roleId: currentRoleId },
      "POST"
    );
    if (isSuccess) {
      rolePermissionList(currentRoleId);
    }
  };

  const MenuItem = ({ item, handleCheckboxChange, parentMenuItem }) => {
    const handleAllCheckboxChange = () => {
      const allChecked = !item.permissions.some(
        (permission) => !JSON.parse(permission.checked)
      );

      item.permissions.forEach((permission) => {
        handleCheckboxChange(
          item.sequence,
          item,
          permission.permissionCode,
          parentMenuItem,
          allChecked
        );
      });
      // onAccordionChange(item.sequence);
    };

    return (
      <div>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header
              style={
                item.sub_menu.length > 0
                  ? { backgroundColor: "#22617a7a" }
                  : { backgroundColor: "" }
              }
            >
              {item.caption}
            </Accordion.Header>
            <Accordion.Body
              className={item.sub_menu.length > 0 ? "" : "border_box"}
            >
              <Row>
                {!showSkelton ? (
                  <>
                    {item.permissions.length > 0 && !item.sub_menu.length && (
                      <Col md={4}>
                        <Form.Check
                          type="checkbox"
                          id={`checkAll${item.sequence}`}
                          label="All"
                          checked={item.permissions.every(
                            (permission) => permission.checked
                          )}
                          onChange={handleAllCheckboxChange}
                        />
                      </Col>
                    )}
                    {item.sub_menu.length > 0
                      ? item.sub_menu.map((subItem) => (
                          <MenuItem
                            key={subItem.sequence}
                            item={subItem}
                            handleCheckboxChange={handleCheckboxChange}
                            parentMenuItem={item}
                            // activeKey={activeKey}
                            // onAccordionChange={onAccordionChange}
                          />
                        ))
                      : item.permissions.map((permission, index) => (
                          <Col key={index} md={4}>
                            <Form.Check
                              type="checkbox"
                              id={`check${index}`}
                              label={permission.view}
                              checked={permission.checked}
                              onChange={() =>
                                handleCheckboxChange(
                                  item.sequence,
                                  item,
                                  permission.permissionCode,
                                  parentMenuItem
                                )
                              }
                            />
                          </Col>
                        ))}
                  </>
                ) : (
                  <Loader />
                )}
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    );
  };

  const Menu = ({ jsonData }) => {
    const updatedPermission = async (menuItem, code) => {
      if (menuItem && menuItem.permissions) {
        const permissionIndex = menuItem.permissions.findIndex(
          (permission) => permission.permissionCode === code
        );
        if (permissionIndex !== -1) {
          const updatedPermissions = [...menuItem.permissions];
          updatedPermissions[permissionIndex].checked =
            !updatedPermissions[permissionIndex].checked;
          const updatedParentMenuItem = {
            ...menuItem,
            permissions: updatedPermissions,
          };
          return updatedParentMenuItem;
        }
      }
    };
    const handleCheckboxChange = async (
      menuSequence,
      menuItem,
      code,
      parentMenuItem
    ) => {
      let tmpUpdated;
      if (parentMenuItem) {
        const updatedParentMenuItem = parentMenuItem.sub_menu.map((subItem) => {
          if (subItem.sequence === menuSequence) {
            return {
              ...subItem,
              ...updatedPermission(subItem, code),
            };
          }
          return subItem;
        });
        tmpUpdated = {
          ...parentMenuItem,
          sub_menu: updatedParentMenuItem,
        };
      } else {
        tmpUpdated = await updatedPermission(menuItem, code);
      }
      const existingDataIndex = updatedPermissions.findIndex(
        (item) => item.sequenceId === menuSequence
      );
      const isAuditor =
        menuItem?.caption === "Internal Auditor" ||
        menuItem?.caption === "External Auditor";
      if (existingDataIndex !== -1) {
        if (isAuditor) {
          console.log(updatedPermissions);
        }
        const updatedPermissionsCopy = [...updatedPermissions];
        updatedPermissionsCopy[existingDataIndex] = {
          sequenceId: menuSequence,
          permission: tmpUpdated,
        };
        setUpdatedPermissions(updatedPermissionsCopy);
      } else {
        setUpdatedPermissions((prevData) => {
          const updatedData = [...prevData];
          const existingPermissionIndex = updatedData.findIndex(
            (item) => item.sequenceId === menuSequence
          );

          if (existingPermissionIndex !== -1) {
            // If sequenceId already exists, update the existing object
            updatedData[existingPermissionIndex] = {
              sequenceId: menuSequence,
              permission: tmpUpdated,
            };
          } else {
            // If sequenceId doesn't exist, add a new object to the array
            updatedData.push({
              sequenceId: menuSequence,
              permission: tmpUpdated,
            });
          }

          return updatedData;
        });
      }
    };
    const handleAccordionChange = (key) => {
      // setActiveAccordion(activeAccordion === key ? null : key);
    };
    return (
      <div>
        {!showSkelton ? (
          jsonData.map((menuItem) => (
            <MenuItem
              key={menuItem.sequence}
              item={menuItem}
              handleCheckboxChange={handleCheckboxChange}
              // activeKey={activeAccordion}
              // onAccordionChange={handleAccordionChange}
            />
          ))
        ) : (
          <Loader />
        )}
      </div>
    );
  };

  useEffect(() => {
    if (filterListValue && filterListValue.length > 0) {
      const initialSelectedItem = filterListValue[0];
      setSelectedItem(initialSelectedItem?.id);
      rolePermissionList(initialSelectedItem?.id);
      setAuditModule(initialSelectedItem.system_created);
    }
  }, [filterListValue]);
  useEffect(() => {
    roleManagementList();
  }, [tab]);

  return (
    <div>
      <div className="Introduction framwork_2">
        <div className="Card__section">
          <Row>
            <Col md={4} style={{ borderRight: "1px dashed #c4c4c4" }}>
              <div className="table_setting">
                <Table striped bordered>
                  <thead>
                    <tr style={{ background: "#ccc" }}>
                      <th style={{ width: 60 }}>Sr. No</th>
                      <th>Management Name</th>
                      <th style={{ width: 60 }}>Action</th>
                    </tr>
                  </thead>
                  {!showSkelton ? (
                    filterListValue && filterListValue?.length > 0 ? (
                      <tbody>
                        {filterListValue &&
                          filterListValue?.map((item, key) => (
                            <tr
                              key={key}
                              style={{
                                background:
                                  selectedItem === item.id
                                    ? "rgb(31 158 209 / 35%)"
                                    : "",
                                cursor: "pointer",
                              }}
                            >
                              <td>{key + 1}</td>
                              <td
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  rolePermissionList(item?.id);
                                  setAuditModule(item.system_created);
                                  setSelectedItem(item?.id);
                                }}
                              >
                                {item?.role_name}
                              </td>

                              <td className="d-flex align-items-center justify-content-center gap-1">
                                {userPermissionList.some(
                                  (permission) =>
                                    permission.permissionCode ===
                                      "REASSIGN_USER" && permission.checked
                                ) && (
                                  <i
                                    className="fas fa-edit"
                                    title="Edit Management"
                                    onClick={() =>
                                      handleEditRoleMasterShow(
                                        item?.id,
                                        item?.role_name
                                      )
                                    }
                                    style={{
                                      pointerEvents: !item?.is_deletable
                                        ? "none"
                                        : "auto",
                                      opacity: !item?.is_deletable ? 0.5 : 1,
                                    }}
                                  ></i>
                                )}
                                &nbsp;&nbsp;
                                {userPermissionList.some(
                                  (permission) =>
                                    permission.permissionCode ===
                                      "REMOVE_USER" && permission.checked
                                ) && (
                                  <div
                                    onClick={() => {
                                      showDeleteAccessPopup(item?.id);
                                    }}
                                  >
                                    <i
                                      style={{color: "red",
                                        pointerEvents: !item?.is_deletable
                                          ? "none"
                                          : "auto",
                                        opacity: !item?.is_deletable ? 0.5 : 1,
                                      }}
                                      class="fas fa-trash-alt"
                                      title="Delete Role"
                                    ></i>{" "}
                                  </div>
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td colSpan={3} className="text-center">
                            No Data Found
                          </td>
                        </tr>
                      </tbody>
                    )
                  ) : (
                    <Loader />
                  )}
                </Table>
              </div>
            </Col>
            <Col md={8}>
              <div
                className="d-flex align-items-center justify-content-between p-2 mb-2"
                style={{ background: "#ccc" }}
              >
                <div className="heading">
                  <p>
                    <b>Permission Group</b>
                  </p>
                </div>
                <div className="directly p-0 hstack gap-2">
                  <input
                    type="search"
                    className="form-control w-100"
                    placeholder="Search"
                    name="search"
                    onChange={(e) => handleSearch(e.target.value)}
                  />
                  {userPermissionList.some(
                    (permission) =>
                      permission.permissionCode === "CREATE_MANAGEMENT_ROLE" &&
                      permission.checked
                  ) && (
                    <Button variant="info" onClick={handleShowAddManagement}>
                      Create Management
                    </Button>
                  )}
                </div>
              </div>
              <div className="access__group__section">
                <div className="access__section">
                  {pemissionList && <Menu jsonData={pemissionList} />}
                </div>
                {answerAccepted && (
                  <div className="access__section">
                    <p>Do you want to audit?</p>
                    <FormGroup>
                      <Form.Check
                        type="checkbox"
                        id={`checkYes`}
                        label="Yes"
                        checked={answerAccepted === "YES" ? true : false}
                        // onChange={() => handleCheckboxChange("YES")}
                      />
                      <Form.Check
                        type="checkbox"
                        id={`checkNo`}
                        label="No"
                        checked={answerAccepted === "NO" ? true : false}
                        // onChange={() => handleCheckboxChange("NO")}
                      />
                    </FormGroup>
                  </div>
                )}
              </div>
              <div className="text-end my-1">
                <Button
                  variant="info"
                  type="submit"
                  onClick={updatePermissionToRole}
                >
                  Assign Permission
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      {/* Add/Edit Management ------------------- */}
      <Modal show={showAddManagement} onHide={handleCloseAddManagement}>
        <Modal.Header closeButton>
          <Modal.Title>
            {mode === "create" ? "Create" : "Update"} Management
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            className="form-control"
            type="text"
            defaultValue={mode === "create" ? "" : roleName}
            placeholder="Enter Management Name"
            onChange={(e) => setRoleTitle(e)}
          />
          <span style={{ color: "red" }}>
            {isError && "This Management Already Exist."}
          </span>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="info"
            type="submit"
            disabled={isError || roleName === "" ? true : false}
            onClick={mode === "create" ? createRoleMaster : updateRoleMaster}
          >
            {mode === "create" ? "Create Management" : "Update Management"}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delete Management ------------------------- */}
      <Modal show={deleteAccessPopup} onHide={closeDeleteAccessPopup}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Are you sure you want to delete?</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-danger"
            type="submit"
            onClick={() => deleteRoleMaster()}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AccessManagement;
