import React, { useRef, useState } from "react";
import Sidebar from "../../../sidebar/sidebar";
import Header from "../../../header/header";
import { NavLink, useLocation } from "react-router-dom";
import { Button, Col, Dropdown, Form, Modal, Row } from "react-bootstrap";
import "./supplier_assessment.css";
import Table from "react-bootstrap/Table";
import Multiselect from "multiselect-react-dropdown";
import CreateSectorQuestionModal from "../../../Sector_Question_Manage/CreateSectorQuestionModal";

export default function Supplier_assessment(props) {
  const location = useLocation();
  const financialYearRef = useRef();
  const multiselectRefTracker = useRef();
  const [assessmentValue, setAssessmentValue] = useState([]);
  const [functionName, setFunctionName] = useState();
  const [modeType, setModeType] = useState();
  const [mode, setMode] = useState("create");

  const [showCreateAssessmentPopup, setShowCreateAssessmentPopup] = useState(false);
  const handleCloseAssessmentPopup = () => {
    setShowCreateAssessmentPopup(false);
    if ("handleShowAddQuestionAssessmentPopup" === functionName) {
      handleShowAddQuestionAssessmentPopup();
    } else if ("handleShowAddQuestionAssessmentPopup" === functionName) {
      handleShowAddQuestionAssessmentPopup();
    }
  }
  const handleShowCreateAssessmentPopup = (e, functionName) => {
    e.preventDefault();
    setFunctionName(functionName)
    setShowCreateAssessmentPopup(true)
    setShowAddQuestionAssessmentPopup(false)
    setMode("create");
  };

  const showUpdateTopicPopup = () => {
    setMode("edit");
    setShowCreateAssessmentPopup(true)
  };

  const [showAddQuestionAssessmentPopup, setShowAddQuestionAssessmentPopup] = useState(false);
  const handleCloseAddQuestionAssessmentPopup = () => {
    setShowFrameworkAssessmentPopup(true);
    setShowAddQuestionAssessmentPopup(false);
  };
  const handleShowAddQuestionAssessmentPopup = () => {
    setShowAddQuestionAssessmentPopup(true);
    setShowFrameworkAssessmentPopup(false);
  };

  const [showFrameworkAssessmentPopup, setShowFrameworkAssessmentPopup] = useState(false);
  const handleCloseFrameworkAssessmentPopup = () => setShowFrameworkAssessmentPopup(false);
  const handleShowFrameworkAssessmentPopup = (mode) => {
    setShowFrameworkAssessmentPopup(true);
    setModeType(mode);
  }


  const [showSupplierPopup, setShowSupplierPopup] = useState(false);
  const handleCloseSupplierPopup = () => setShowSupplierPopup(false);
  const handleShowSupplierPopup = () => setShowSupplierPopup(true);

  const [showAddQuestionPopup, setShowAddQuestionPopup] = useState(false);
  const handleCloseAddQuestionPopup = () => setShowAddQuestionPopup(false);
  const handleShowAddQuestionPopup = () => setShowAddQuestionPopup(true);

  return (
    <div>
      <Sidebar dataFromParent={location?.pathname} />
      <Header />
      <div className="main_wrapper">
        <section className="inner_wraapper">
          <div className="steps-form">
            <div className="steps-row setup-panel">
              <div className="tabs-top setting_admin my-0">
                <ul>
                  <li>
                    <NavLink to={"#"} className="activee">Supplier Assesment Lististing</NavLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className="dropping_btn">
              <Dropdown className="mx-2">
                <Dropdown.Toggle variant="info" id="dropdown-basic">
                  Create Assesment
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={(e) => handleShowCreateAssessmentPopup(e, "noFunction")}>New Assessment</Dropdown.Item>
                  <Dropdown.Item onClick={() => handleShowFrameworkAssessmentPopup("PreviousAssessment")}>Previous Assessment</Dropdown.Item>
                  <Dropdown.Item onClick={() => handleShowFrameworkAssessmentPopup("ByFrameworks")}>By Frameworks</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div className="Introduction">
            <div className="question_section">
              <div className="table_f manage-detail admin-risk-table table-responsive">
                <Table striped bordered hover>
                  <thead>
                    <tr className="heading_color">
                      <th style={{ width: 55 }}>Sr</th>
                      <th>Assessment Title</th>
                      <th>Financial Year</th>
                      <th style={{ width: 150, textAlign: "center" }}>
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>Assessment 1</td>
                      <td>2023-24</td>
                      <td>
                        <div className="d-flex justify-content-center gap-3">
                          <i title="Add Question" className="fas fa-plus-square" onClick={() => handleShowAddQuestionPopup()}></i>
                          <i title="Edit Assessment" className="fas fa-edit" onClick={(e) => showUpdateTopicPopup()}></i>
                          <i title="View Question" className="fas fa-eye"></i>
                          <i title="Assign" className="fas fa-sign-in-alt" onClick={() => handleShowSupplierPopup()}></i>
                          <i title="Delete Assessment" className="fas fa-trash-alt" style={{ color: "red" }}></i>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* Create Assessment Popup --------------------------------------------- */}
      <Modal show={showCreateAssessmentPopup} onHide={handleCloseAssessmentPopup}>
        <Modal.Header closeButton>
          <Modal.Title> {mode === "create" ? "Create" : "Update"}  Assessment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={6}>
              <Form.Control required type="text" placeholder="Assessment Title" />
            </Col>
            <Col md={6}>
              <div>
                <select className="select___year" ref={financialYearRef}>
                  <option disabled selected> Select Financial Year </option>
                  <option > 2023-24  </option>
                </select>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="info"> {mode === "create" ? "Create" : "Update"} </Button>
        </Modal.Footer>
      </Modal>

      {/* Add New Assessment Popup --------------------------------------------- */}
      <Modal show={showAddQuestionAssessmentPopup} onHide={handleCloseAddQuestionAssessmentPopup} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Add Question</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label>Select Question</Form.Label>
          <Multiselect
            displayValue="title"
            options={assessmentValue}
            ref={multiselectRefTracker}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="info" onClick={(e) => handleShowCreateAssessmentPopup(e, modeType === "ByFrameworks" ? "handleShowAddQuestionAssessmentPopup" : "handleShowAddQuestionAssessmentPopup")}> Submit </Button>
        </Modal.Footer>
      </Modal>

      {/* Previous Assessment Popup --------------------------------------------- */}
      <Modal show={showFrameworkAssessmentPopup} onHide={handleCloseFrameworkAssessmentPopup}>
        <Modal.Header closeButton>
          <Modal.Title>{modeType === "ByFrameworks" ? "Framework" : "Previous Assessment"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label>Select {modeType === "ByFrameworks" ? "Framework" : "Previous Assessment"}</Form.Label>
          <Multiselect
            displayValue="title"
            options={modeType === "ByFrameworks" ? assessmentValue : assessmentValue}
            ref={multiselectRefTracker}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="info" onClick={modeType === "ByFrameworks" ? handleShowAddQuestionAssessmentPopup : handleShowAddQuestionAssessmentPopup}> Add Assessment </Button>
        </Modal.Footer>
      </Modal>

      {/*Add Question Popup --------------------------------------------- */}
      <Modal show={showAddQuestionPopup} onHide={handleCloseAddQuestionPopup} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Add Question</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <Multiselect
              displayValue="title"
              options={assessmentValue}
              ref={multiselectRefTracker}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="danger" onClick={handleCloseAddQuestionPopup}>
            Close
          </Button> */}
          <Button variant="info" onClick={handleCloseAddQuestionPopup}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Assign Supplier Popup --------------------------------------------- */}
      <Modal show={showSupplierPopup} onHide={handleCloseSupplierPopup}>
        <Modal.Header closeButton>
          <Modal.Title>Assign Assessment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            {/* <Form.Label>Select Supplier</Form.Label> */}
            <Form.Select
              style={{ height: 40 }}
              aria-label="Default select example"
            >
              <option>Select Supplier</option>
              <option value="1">Roop Chandra</option>
              <option value="2">Dipak</option>
              <option value="3">Mithilesh Kumar</option>
              <option value="4">Satish Kumar Gautam</option>
            </Form.Select>
            {/* <Multiselect
              displayValue="key"
              options={supplierList.map((supplier) => ({
                cat: supplier.id,
                key: supplier.first_name + " " + supplier.last_name,
              }))}
              showCheckbox={false}
            /> */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="info">Assign Assessment</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
