import React, { useEffect, useState } from "react";
import Header from "../../header/header";
import Sidebar from "../../sidebar/sidebar";
import { NavLink, useLocation } from "react-router-dom";
import { Form, Table } from "react-bootstrap";
import AuditListingFilter from "../../Company Sub Admin/Component/Sector Questions/Filter/AuditListingFilter";
import { apiCall } from "../../../_services/apiCall";
import config from "../../../config/config.json";

const AuditHistory = () => {
    const location = useLocation();
    const [financialYear, setFinancialYear] = useState([]);
    const getFinancialYear = async () => {
        const { isSuccess, data } = await apiCall(
            `${config.POSTLOGIN_API_URL_COMPANY}getFinancialYear`,
            {},
            {},
            "GET"
        );
        if (isSuccess) {
            setFinancialYear(data.data);
            console.log(data.data)
        }
    };
    useEffect(() => {
        getFinancialYear();
      }, []);
    return (
        <>
            <Sidebar dataFromParent={location?.pathname} />
            <Header />
            <div className="main_wrapper">
                <section className="inner_wraapper">
                    <div className="hol_rell">
                        <div className="steps-form">
                            <div className="steps-row setup-panel">
                                <div className="tabs-top setting_admin my-0">
                                    <ul>
                                        <li>
                                            <NavLink to="/audit-listing"> Audit Listing </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/audit-history" className="activee"> Audit History </NavLink>
                                        </li>
                                    </ul>
                                </div>
                                <div className="hstack gap-3">
                                    <div className="filter_ICOn">
                                        <AuditListingFilter />
                                    </div>
                                    <Form.Select style={{ width: 125 }} className="mx-2" aria-label="Default select example">
                                        <option>Select Entity</option>
                                        {/* <option value="1">Supplier</option> */}
                                        <option value="2">Company</option>
                                    </Form.Select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="Introduction history__sections">
                        <div className="question_section">
                            <Table striped hover bordered className="m-0" style={{ cursor: "pointer" }}>
                                <thead>
                                    <tr className="fixed_tr_section">
                                        <th style={{ width: 55 }}>Sr No</th>
                                        <th>Financial Year</th>
                                        <th style={{ width: 100 }}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {console.log(financialYear)}
                                {financialYear && financialYear?.map(
                                      (data, index) => {
                                        <tr>
                                        <td>{index+1}</td>
                                        <td>{data?.financial_year_value}</td>
                                        <td>
                                            <div className="hstack gap-3 justify-content-center">
                                                <NavLink to={"/framework-history"}>
                                                    <i className="fa fa-eye" title="View Framework"></i>
                                                </NavLink>
                                                <NavLink to={"/answer-history"}>
                                                    <i className="fas fa-file-invoice" title="View Question"></i>
                                                </NavLink>
                                                <i className="fa fa-download" title="Download Report"></i>
                                            </div>
                                        </td>
                                    </tr>
                                      }
                                    )}
                                   
                                   <tr>
                                        <td>2</td>
                                        <td>2023 - 2024</td>   
                                        <td>
                                            <div className="hstack gap-3 justify-content-center">
                                                <NavLink to={"/framework-history"}>
                                                    <i className="fa fa-eye" title="View Framework"></i>
                                                </NavLink>
                                                <NavLink to={"/answer-history"}>
                                                    <i className="fas fa-file-invoice" title="View Question"></i>
                                                </NavLink>
                                                <i className="fa fa-download" title="Download Report"></i>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default AuditHistory