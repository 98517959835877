import React, { useEffect, useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import foot_Logo from "../../img/logol.png";
import head_Logo from "../../img/zais-logo.png";
import "./common.css";
import "./sidebar.css";
import config from "../../config/config.json";
import { PermissionMenuContext } from "../../contextApi/permissionBasedMenuContext";
// import { authenticationService } from "../../_services/authentication";

const Sidebar = (props) => {
  const contextType = useContext(PermissionMenuContext);
  const [menuList, setMenuList] = useState([]);

  useEffect(() => {
    const settingsMenu = JSON.parse(localStorage.getItem("menu"));
    setMenuList(settingsMenu);
  }, [contextType.state]);

  const currentURL = window.location.href;
  const splitURL = currentURL.split("/");
  const activeURL = splitURL[4];
  const { Defaults } = props;
  // const { Item, Header, Body } = Accordion;
  return (
    // export default Sidebar
    // export default class sidebar extends Component {
    // constructor(props) {
    //   super(props)
    //   this.state = {
    //     menuList: [],
    //   }
    // }
    // static contextType = PermissionMenuContext;
    // componentDidMount() {
    //   // const  currentUser  = authenticationService?.currentUserSubject?.getValue();
    //   const settingsMenu = JSON.parse(localStorage.getItem("menu"));

    //   console.log("settingsMenu",settingsMenu)
    //   this.setState({ menuList: settingsMenu });
    // }
    // render() {
    // const currentURL = window.location.href;
    // const splitURL = currentURL.split("/");
    // const activeURL = splitURL[4];
    // const { Defaults } = this.props;
    // const { Item, Header, Body } = Accordion;
    // return (
    <div>
      <div className="d-flex" id="wrapper">
        {/* <!-- Sidebar--> */}
        <div
          className="border-end bg-white active-sidebar"
          id="sidebar-wrapper"
        >
          <div className="sidebar-heading color_xl">
            <div className="logo_text">
              <NavLink to="/home">
                {" "}
                <img src={head_Logo} alt="" />{" "}
              </NavLink>
            </div>
          </div>
          <div
            className="list-group list-group-flush"
            // style={{ width: "300px" }}
          >
            <div className="route-dom">
              <ul className="home_icon_img">
                {/* {this.context && this.context?.toggleUserRole === "supplier" && 
                        this.state.menuSupplierData && this.state.menuSupplierData?.menu && 
                        this.state.menuSupplierData?.menu.map((data,index) =>{
                           return(<li key={index}>
                            {data?.sub_menu?.length > 0 ? (
                            <Accordion defaultActiveKey={Defaults}>
                              <Item eventKey="0">
                                <Header className="list-group-item-light audit_heading">
                                  {data?.icon}
                                  <span className="home_boom ">
                                    {data?.caption}
                                  </span>
                                </Header>
                                <Body className="text_body">
                                  {data?.sub_menu?.map((menu, index) => {
                                    return (
                                      <NavLink
                                        key={index}
                                        to={`${menu?.url}`}
                                        className="list-group-item list-group-item-action list-group-item-light p-3 nop"
                                      >
                                        {menu?.icon}
                                        <span className="home_boom">
                                          {menu?.caption}
                                        </span>
                                      </NavLink>
                                    );
                                  })}
                                </Body>
                              </Item>
                            </Accordion>
                          ) : (
                            <NavLink
                              activeClassName="active"
                              to={`${data?.url}`}
                              className="list-group-item list-group-item-action list-group-item-light p-3 nop"
                            >
                              {data?.icon}
                              <span className="home_boom">{data?.caption}</span>
                            </NavLink>
                          )}
                           </li>)
                        })
               
                  }
              */}
                {/* {menuList?.map((data, index) => {
                    return (
                      <li key={index}>
                        {data?.sub_menu?.length > 0 ? (
                          <Accordion defaultActiveKey={Defaults}>
                            <Item eventKey="0">
                              <Header className="list-group-item-light audit_heading p-0 m-0">
                                <img src={`${config.BASE_URL}${data?.inactiveIcon}`} alt="" />
                                <span className="home_boom" style={{ textTransform: 'uppercase', fontSize: '13px', fontWeight: '600' }}>
                                  {data?.caption}
                                </span>
                              </Header>
                              <Body className="text_body">
                                {data?.sub_menu?.map((menu, index) => {
                                  return (
                                    <NavLink
                                      key={index}
                                      to={`${menu?.url}`}
                                      className="list-group-item list-group-item-action list-group-item-light p-3 nop"
                                    >
                                      {activeURL === menu?.url ?
                                        <img src={`${config.BASE_URL}${menu?.activeIcon}`} alt="" />
                                        :
                                        <img src={`${config.BASE_URL}${menu?.inactiveIcon}`} alt="" />
                                      }
                                      <span className="home_boom">
                                        {menu?.caption}
                                      </span>
                                    </NavLink>
                                  );
                                })}
                              </Body>
                            </Item>
                          </Accordion>
                        ) : (
                          <NavLink
                            activeClassName="active"
                            to={`/${data?.url}`}
                            className="list-group-item list-group-item-action list-group-item-light p-3 nop"
                          >
                            {activeURL === data?.url ?
                              <img src={`${config.BASE_URL}${data?.activeIcon}`} alt="" />
                              :
                              <img src={`${config.BASE_URL}${data?.inactiveIcon}`} alt="" />
                            }
                            <span className="home_boom">{data?.caption}</span>
                          </NavLink>
                        )}
                      </li>
                    );
                  })} */}

                {menuList?.map((data, index) => {
                  return (
                    <li key={index}>
                      <NavLink
                        activeClassName="active"
                        to={`/${data?.url}`}
                        className="list-group-item list-group-item-action list-group-item-light p-3 nop"
                      >
                        {activeURL === data?.url ? (
                          <img
                            src={`${config.BASE_URL}${data?.activeIcon}`}
                            alt=""
                          />
                        ) : (
                          <img
                            src={`${config.BASE_URL}${data?.inactiveIcon}`}
                            alt=""
                          />
                        )}
                        <span className="home_boom">{data?.caption}</span>
                      </NavLink>
                    </li>
                  );
                })}
                {/* <li>
                    <NavLink
                      activeClassName="active"
                      to="/esg_reporting"
                      className={
                        company[1] === "esg_reporting"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "esg_reporting_pie"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : "list-group-item list-group-item-action list-group-item-light p-3 nop"
                      }
                    >
                      <i className="fal fa-file-chart-line"></i>
                      <span className="home_boom">ESG Reporting</span>
                    </NavLink>
                  </li> */}
                {/* <li>
                    <NavLink
                      activeClassName="active"
                      to="/sector_questions"
                      className={
                        company[1] === "sector_question"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "Environmental_Capital"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "sector_questions"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "Leadership_Governance"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "Business_Model_Innovation"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "sector_question_detail"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "Cyber_Digital"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "Human_Capital"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : "list-group-item list-group-item-action list-group-item-light p-3 nop"
                      }
                    >
                      <i className="far fa-clipboard"></i>
                      <span className="home_boom">Sector Questions</span>
                    </NavLink>
                  </li> */}
                {/* <li>
                    <Accordion defaultActiveKey={Defaults}>
                      <Item eventKey="0">
                        <Header className="list-group-item-light audit_heading">
                          <i className="fal fa-file-chart-line"></i>
                          <span className="home_boom ">Audit</span>
                        </Header>
                        <Body className="text_body">
                          <NavLink
                            to="/audit_question_listing"
                            className="list-group-item list-group-item-action list-group-item-light p-3 nop"
                          >
                            <i className="fal fa-file-chart-line"></i>
                            <span className="home_boom">Audit Listing</span>
                          </NavLink>

                          <NavLink
                            to="/audit_history_listing"
                            className="list-group-item list-group-item-action list-group-item-light p-3 nop"
                          >
                            <i className="fal fa-file-chart-line"></i>
                            <span className="home_boom">Audit History</span>
                          </NavLink>
                        </Body>
                      </Item>
                    </Accordion>
                  </li> */}
                {/* <li>
                    <NavLink
                      activeClassName="active"
                      to="/Governance_Policies"
                      a="true"
                      className={
                        company[1] === "governance"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "Governance_Policies"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "Social_Policies"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "Cyber_&_Technology"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "Health_&_Safety_Policy"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "Environmental_Policy"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "other_policies"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : "list-group-item list-group-item-action list-group-item-light p-3 nop"
                      }
                    >
                      <i className="far fa-building"></i>
                      <span className="home_boom">Governance</span>
                    </NavLink>
                  </li> */}
                {/* <li>
                    <NavLink
                      activeClassName="active"
                      to="/supplier_management_detail"
                      className={
                        company[1] === "supplier_management_detail"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "supplier_management_option"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "supplier_management_form"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "supplier_management_detail"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : "list-group-item list-group-item-action list-group-item-light p-3 nop"
                      }
                    >
                      <i className="fal fa-file-chart-line"></i>
                      <span className="home_boom">Supplier Management</span>
                    </NavLink>
                  </li> */}
                {/* <li>
                    <NavLink
                      activeClassName="active"
                      to={sustainableTarget}
                      className={
                        company[1] === "sustainable"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "sustainable_submissions"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : "list-group-item list-group-item-action list-group-item-light p-3 nop"
                      }
                    >
                      <span className="leaf-fer">
                        <i className="fal fa-leaf"></i>
                        <span className="home_boom text_upper">SDGs</span>
                      </span>
                    </NavLink>
                  </li> */}
                {/* <li>
                    <NavLink
                      activeClassName="active"
                      to="/Scope_1_Emissions"
                      className={
                        company[1] === "carbon_footprint"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "Scope_1_Emissions"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "Scope_2_Emissions"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "Scope_3_Emissions"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "result"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "carbon_footprint_detail"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : "list-group-item list-group-item-action list-group-item-light p-3 nop"
                      }
                    >
                      <i className="fal fa-shoe-prints"></i>
                      <span className="home_boom">Carbon Footprint</span>
                    </NavLink>
                  </li> */}
                {/* <li>
                    <NavLink
                      activeClassName="active"
                      to="/board_skills_detail"
                      className={
                        company[1] === "board_skills"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "board_skills_detail"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : "list-group-item list-group-item-action list-group-item-light p-3 nop"
                      }
                    >
                      <i className="fal fa-clipboard"></i>
                      <span className="home_boom">Board Skills</span>
                    </NavLink>
                  </li> */}
                {/* <li>
                    <NavLink
                      activeClassName="active"
                      to="/management_details"
                      className={
                        company[1] === "management"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "management_details"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : "list-group-item list-group-item-action list-group-item-light p-3 nop"
                      }
                    >
                      <i className="fal fa-users"></i>
                      <span className="home_boom">Management</span>
                    </NavLink>
                  </li> */}
                {/* <li>
                    <NavLink
                      activeClassName="active"
                      to="/esg_products"
                      className="list-group-item list-group-item-action list-group-item-light p-3 nop"
                    >
                      <i className="fal fa-archive"></i>
                      <span className="home_boom">ESG Products</span>
                    </NavLink>
                  </li> */}
                {/* <li>
                    <NavLink
                      activeClassName="active"
                      to="/training"
                      className="list-group-item list-group-item-action list-group-item-light p-3 nop"
                    >
                      <i className="fal fa-address-card"></i>
                      <span className="home_boom">Training</span>
                    </NavLink>
                  </li> */}

                {/* <li>
                    <NavLink
                      activeClassName="active"
                      to="/global_controls"
                      className={
                        company[1] === "controls"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "industry_categories"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "add_industry_type"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "add_industry"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "industry"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "questions_framework_wise"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "add_sector_questions"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "sector_questions"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "innovative_programmes"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "add_innovative_programmes"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "program"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "introduction_videos"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "video"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "add_introduction_videos"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "policies"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "add_new_policy"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "policy"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "governance_policies"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "cyber_&_technology_policies"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "social_policies"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "helth_&_sefty_policies"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "environmental_policies"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "other_policies"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : "list-group-item list-group-item-action list-group-item-light p-3 nop"
                      }
                    >
                      <i className="far fa-cog"></i>
                      <span className="home_boom">Global Controls</span>
                    </NavLink>
                  </li> */}
                {/* <li>
                    <NavLink
                      activeClassName="active"
                      to="/settings"
                      className={
                        company[1] === "Setting_Profile"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "Setting_Billing"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "Google_2FA"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "sub_accounts"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : "list-group-item list-group-item-action list-group-item-light p-3 nop"
                      }
                    >
                      <i className="fal fa-cog"></i>
                      <span className="home_boom">Settings</span>
                    </NavLink>
                  </li> */}
              </ul>
            </div>
          </div>
          <div className="slill_bord">
            <img src={foot_Logo} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
