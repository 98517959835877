import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { config } from "rxjs";
import UploadIcon from "./Icons/uploadIcon";
import DownloadIcon from "./Icons/downloadIcon";
import GaugeChart from "react-gauge-chart";
import { apiCall } from "../../../../_services/apiCall";
import swal from "sweetalert";

export default function TebularInputCardAnswer({
  filterAssignedDetail,
  item,
  combinedAnswers,
  userIsHead,
  userIsHistory,
  meterListData,
  auditAnswer,
  handleValidateSubmit,
  listing
}) {
  console.log(filterAssignedDetail,"dsgfrdgrty56tyw",combinedAnswers)
  const [nrOfLevels, setNrOfLevels] = useState(2);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const handleClose = () => setShow(false);
  const handleClose1 = () => setShow1(false);
  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow1(true);
  const handleClose2 = () => setShow2(false);
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const userId = currentUser?.id;
  const [show2, setShow2] = useState(false);
 


  function getProcessById(dataArray, inputId) {
    const foundData = dataArray.find((data) => data.id == inputId);
    return foundData ? foundData.location : null;
  }

  const chartStyle = {
    width: 60,
    height: 20
  }

  return (
    <>
      <div className="table__wrappers">
        {
          combinedAnswers?.length > 0 &&
          combinedAnswers?.map((combinedAnswers) => {
            return (
              <>
              {console.log(combinedAnswers,"sadfery")}
                <hr className="hr-text" data-content="1st Answer" />
                <div className="tableOutput__area">
                  <Table striped bordered hover>
                    <tbody>

                    {filterAssignedDetail && (
                  <>
                    <tr style={{ background: "#ccc" }}>
                      <td>Attribute</td>
                      <td>Value</td>
                    </tr>
                    {combinedAnswers?.sourceId && (
                        <tr>
                          <td>Source</td>
                          <td>
                            <span style={{ fontWeight: 100 }}>
                              {getProcessById(
                                meterListData,
                                combinedAnswers?.sourceId
                              )}
                            </span>
                          </td>
                        </tr>
                      )}
                    <tr>
                      <td>Assign By</td>
                      <td>
                        {filterAssignedDetail?.assignedByDetails && filterAssignedDetail?.assignedByDetails.map(
                          (user, index) => (
                            <span key={index}  >
                              <span
                                data-tooltip={filterAssignedDetail?.assignedByDetails && filterAssignedDetail?.assignedByDetails
                                  .map((user) => user.email)
                                  .join(", ")}
                              >
                                {user.first_name} {user.last_name}
                              </span>

                              {index <
                                filterAssignedDetail?.assignedByDetails.length -
                                1 && ", "}
                            </span>
                          )
                        )}
                      </td>
                    </tr>

                    <tr>
                      <td>Assign to</td>
                      <td>
                        {filterAssignedDetail?.assignedToDetails && filterAssignedDetail?.assignedToDetails.map(
                          (user, index) => (
                            <span key={index}>
                              <span
                                data-tooltip={filterAssignedDetail?.assignedToDetails
                                  .map((user) => user.email)
                                  .join(", ")}
                              >
                                {user.first_name} {user.last_name}
                              </span>
                              {index <
                                filterAssignedDetail?.assignedToDetails.length -
                                1 && ", "}
                            </span>
                          )
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Assign Date</td>
                      <td>
                        {new Date(
                          filterAssignedDetail.createdAt
                        ).toLocaleString()}
                      </td>
                    </tr>

                    <tr>
                      <td>Due Date</td>
                      <td>
                        <div className="hstack">
                          <p className="m-0">
                            {new Date(
                              filterAssignedDetail.dueDate
                            ).toLocaleString()}
                          </p>
                          <GaugeChart
                            style={chartStyle}
                            id="gauge-chart2"
                            nrOfLevels={nrOfLevels}
                            colors={["#FF5F6D", "#FFC371"]}
                          // percent={completionPercentage / 100}
                          />
                         
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>History</td>
                      <td>View</td>
                    </tr>
                  </>
                )}
                {/* {qualitativeStatus?.length > 0 && (
                  <tr>
                    <td>Status</td>
                    <td>{qualitativeStatus?.replace(/_/g, " ")}</td>
                  </tr>
                )}
                {qualitativeAnswerBy?.length > 0 && (
                  <tr>
                    <td style={{ width: "150px" }}>Answer By</td>
                    <td>{qualitativeAnswerBy}</td>
                  </tr>
                )}
                {qualitativeRemark?.length > 0 && (
                  <tr>
                    <td> Auditor Remark</td>
                    <td>{qualitativeRemark}</td>
                  </tr>
                )} */}

                {/* {filterAssignedDetail && <tr>
                  <td>Attatchment</td>
                  <td>
                    {attatchment ? (
                      <DownloadIcon attatchment ={attatchment} />
                    )}
                  </td>
                </tr>} */}
                      {/* <tr style={{ background: "#ccc" }}>
                        <td>Attribute</td>
                        <td>Value</td>
                      </tr>
                      {combinedAnswers?.sourceId && (
                        <tr>
                          <td>Source</td>
                          <td>
                            <span style={{ fontWeight: 100 }}>
                              {getProcessById(
                                meterListData,
                                combinedAnswers?.sourceId
                              )}
                            </span>
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td>Assign By</td>
                        <td>
                        {filterAssignedDetail?.assignedByDetails && filterAssignedDetail?.assignedByDetails.map(
                          (user, index) => (
                            <span key={index}  >
                              <span
                                data-tooltip={filterAssignedDetail?.assignedByDetails && filterAssignedDetail?.assignedByDetails
                                  .map((user) => user.email)
                                  .join(", ")}
                              >
                                {user.first_name} {user.last_name}
                              </span>

                              {index <
                                filterAssignedDetail?.assignedByDetails.length -
                                1 && ", "}
                            </span>
                          )
                        )}
                      </td>
                      </tr>
                      <tr>
                      <td>Assign to</td>
                      <td>
                        {filterAssignedDetail?.assignedToDetails && filterAssignedDetail?.assignedToDetails.map(
                          (user, index) => (
                            <span key={index}>
                              <span
                                data-tooltip={filterAssignedDetail?.assignedToDetails
                                  .map((user) => user.email)
                                  .join(", ")}
                              >
                                {user.first_name} {user.last_name}
                              </span>
                              {index <
                                filterAssignedDetail?.assignedToDetails.length -
                                1 && ", "}
                            </span>
                          )
                        )}
                      </td>
                    </tr>
                      <tr>
                        <td>Assign Date</td>
                        <td>12/Dec/2023 | 23:59</td>
                      </tr>
                      <tr>
                        <td>Audited Date</td>
                        <td>12/Dec/2023 | 23:59</td>
                      </tr>
                      <tr>
                        <td>Audited By</td>
                        <td>Dipak</td>
                      </tr>
                      <tr>
                        <td>Audited Status</td>
                        <td>Accepted</td>
                      </tr>
                      <tr>
                        <td>Due Date</td>
                        <td>
                          <div className="hstack">
                            <p className="m-0">05/Jan/2024 | 23:59</p>
                            <GaugeChart
                              style={chartStyle}
                              id="gauge-chart2"
                              nrOfLevels={nrOfLevels}
                              colors={["#FF5F6D", "#FFC371"]}
                              // percent={completionPercentage / 100}
                            />
                          </div>
                        </td>
                      </tr>
                      {userIsHistory !== 1 && combinedAnswers?.audit_status && (
                        <tr>
                          <td>Status</td>
                          <td>
                            {combinedAnswers?.audit_status?.replace(
                              /_/g,
                              " "
                            ) === "REJECTED"
                              ? "Rejected"
                              : "ACCEPTED"
                              ? "Accepted"
                              : "Verified"}
                          </td>
                        </tr>
                      )}
                      {combinedAnswers?.answered_by_email && (
                        <tr>
                          <td style={{ width: "150px" }}>Answer By</td>
                          <td>{combinedAnswers?.answered_by_email}</td>
                        </tr>
                      )}
                      {userIsHistory !== 1 && combinedAnswers?.audit_remark && (
                        <tr>
                          <td>Auditor Remark</td>
                          <td>
                            <button
                              className="new_button_style"
                              onClick={handleShow}
                            >
                              {" "}
                              View Remark{" "}
                            </button>
                            <Modal show={show} onHide={handleClose}>
                              <Modal.Header closeButton>
                                <Modal.Title>Auditor Remark</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <div>
                                  <span style={{ fontWeight: 100 }}>
                                    {" "}
                                    {combinedAnswers?.audit_remark}
                                  </span>
                                </div>
                              </Modal.Body>
                            </Modal>
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td>Attatchment</td>
                        <td>
                          <UploadIcon />
                        </td>
                      </tr>
                      <tr>
                        <td>History</td>
                        <td>
                          <div
                            onClick={handleShow1}
                            style={{ cursor: "pointer" }}
                          >
                            {" "}
                            View
                          </div>
                        </td>
                      </tr> */}
                    </tbody>
                  </Table>
                  <div className="table_width">
                    <div className="table-wapper">
                      <table className="table">
                        <thead>
                          <tr className="option_wrapper">
                            <th className="p-0"> </th>
                            {item?.question_detail &&
                              item?.question_detail
                                .filter(function (item) {
                                  return item?.option_type == "column";
                                })
                                .map((col, ind) => {
                                  return <th key={ind}>{col?.option}</th>;
                                })}
                          </tr>
                        </thead>
                        <tbody>
                          {item?.question_detail &&
                            item?.question_detail
                              .filter(function (item) {
                                return item.option_type == "row";
                              })
                              .map((row, indRow) => {
                                return (
                                  <tr key={indRow} style={{ fontSize: "12px" }}>
                                    <th className="option_wrapper_width_100">
                                      {row?.option}
                                    </th>
                                    {item?.question_detail &&
                                      item?.question_detail
                                        .filter(function (item) {
                                          return item.option_type == "column";
                                        })
                                        .map((col, indCol) => {
                                          const value =
                                            (combinedAnswers?.answer &&
                                              combinedAnswers?.answer[indRow] &&
                                              combinedAnswers?.answer[indRow][
                                                indCol
                                              ]) ||
                                            "";
                                          return (
                                            <td key={indCol}>
                                              <>
                                                {console.log(
                                                  "combinedAnswers?.answer",
                                                  combinedAnswers?.answer
                                                )}
                                              </>
                                              <input
                                                value={value}
                                                type={"text"}
                                                readOnly
                                              />
                                            </td>
                                          );
                                        })}
                                  </tr>
                                );
                              })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {listing === "audit" && auditAnswer?.auditorId?.auditerId === userId &&
          <div className="hstack gap-3 my-2 justify-content-end">
            <Button
              type="submit"
              name="ACCEPTED"
              variant="info"
              onClick={(e) => handleValidateSubmit(e.target.name,"tabular_question",combinedAnswers?.id,combinedAnswers?.financialYearId)}
            >
              Accept
            </Button>
            <Button
              type="submit"
              name="REJECTED"
              variant="danger"
              onClick={(e) => handleValidateSubmit(e.target.name,"tabular_question",combinedAnswers?.id,combinedAnswers?.financialYearId)}
            >
              Reject
            </Button>
            {/* <Button variant="warning">History</Button> */}
          </div>
        }
              </>
            );
          })}
      </div>
   
      <Modal show={show1} onHide={handleClose1} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>History</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <hr className="hr-text" data-content="1st History" />
            <Table striped hover bordered className="m-0">
              <tbody>
                <tr style={{ background: "#ccc" }}>
                  <td>Attribute</td>
                  <td>Valve</td>
                </tr>
                <tr>
                  <td>Assign By</td>
                  <td>Satya</td>
                </tr>
                <tr>
                  <td>Due Date</td>
                  <td>
                    <div className="hstack">
                      <p className="m-0">05/Jan/2024 | 23:59</p>
                      <GaugeChart
                        style={chartStyle}
                        id="gauge-chart2"
                        nrOfLevels={nrOfLevels}
                        colors={["#FF5F6D", "#FFC371"]}
                        // percent={completionPercentage / 100}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td>Rejected</td>
                </tr>
                <tr>
                  <td>Answer By</td>
                  <td>satya@sapidblue.com</td>
                </tr>
                <tr>
                  <td>Attatchment</td>
                  <td>Uploaded</td>
                </tr>
                <tr>
                  <td colSpan={2}>Answer</td>
                </tr>
                <tr>
                  <td colSpan={2} className="p-0 m-0">
                    <textarea className="w-100 form-control"></textarea>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
          <div>
            <hr className="hr-text" data-content="2nd History" />
            <Table striped hover bordered className="m-0"> 
              <tbody>
                <tr style={{ background: "#ccc" }}>
                  <td>Attribute</td>
                  <td>Valve</td>
                </tr>
                <tr>
                  <td>Assign By</td>
                  <td>Satya</td>
                </tr>
                <tr>
                  <td>Due Date</td>
                  <td>
                    <div className="hstack">
                      <p className="m-0">05/Jan/2024 | 23:59</p>
                      <GaugeChart
                        style={chartStyle}
                        id="gauge-chart2"
                        nrOfLevels={nrOfLevels}
                        colors={["#FF5F6D", "#FFC371"]}
                        // percent={completionPercentage / 100}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td>Rejected</td>
                </tr>
                <tr>
                  <td>Answer By</td>
                  <td>satya@sapidblue.com</td>
                </tr>
                <tr>
                  <td>Attatchment</td>
                  <td>Uploaded</td>
                </tr>
                <tr>
                  <td colSpan={2}>Answer</td>
                </tr>
                <tr>
                  <td colSpan={2} className="p-0 m-0">
                    <textarea className="w-100 form-control"></textarea>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
          <div>
            <hr className="hr-text" data-content="3rd History" />
            <Table striped hover bordered className="m-0">
              <tbody>
                <tr style={{ background: "#ccc" }}>
                  <td>Attribute</td>
                  <td>Valve</td>
                </tr>
                <tr>
                  <td>Assign By</td>
                  <td>Satya</td>
                </tr>
                <tr>
                  <td>Due Date</td>
                  <td>
                    <div className="hstack">
                      <p className="m-0">05/Jan/2024 | 23:59</p>
                      <GaugeChart
                        style={chartStyle}
                        id="gauge-chart2"
                        nrOfLevels={nrOfLevels}
                        colors={["#FF5F6D", "#FFC371"]}
                        // percent={completionPercentage / 100}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td>Accepted</td>
                </tr>
                <tr>
                  <td>Answer By</td>
                  <td>satya@sapidblue.com</td>
                </tr>
                <tr>
                  <td>Attatchment</td>
                  <td>Uploaded</td>
                </tr>
                <tr>
                  <td colSpan={2}>Answer</td>
                </tr>
                <tr>
                  <td colSpan={2} className="p-0 m-0">
                    <textarea className="w-100 form-control"></textarea>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
