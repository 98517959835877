import React, { useEffect, useState } from "react";
import "../Sector_Question_Manage/control.css";
import config from "../../config/config.json";
import { authenticationService } from "../../_services/authentication";
import { apiCall } from "../../_services/apiCall";
import QuestionTypeTabSection from "../Company Sub Admin/Component/Sector Questions/QuestionTypeTabSection";

export const SectorQuestionFrameworkWise = (props) => {
  const { viewQuestionList } = props;
  const [sectorQuestion, setSectorQuestion] = useState([]);
  const [menulist, setMenuList] = useState([]);
  const [selectedQuestionType, setSelectedQuestionType] = useState("All");

  const getCustomSectorQuestion = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getCustomSectorQuestion`,
      {},
      {
        frameworkId: viewQuestionList?.frameworkId,
        topicId: viewQuestionList?.topicId,
        kpiId: viewQuestionList?.kpiId,
      },
      "GET"
    );
    if (isSuccess) {
      setSectorQuestion(data?.data?.reverse());
    }
  };

  const deleteSectorQuestion = async (questionId) => {
    const body = {
      questionId: questionId,
    };
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}deleteCustomSectorQuestion`,
      {},
      body,
      "POST"
    );
    if (isSuccess) {
      getCustomSectorQuestion();
    }
  };

  useEffect(() => {
    const currentUser = authenticationService?.currentUserSubject?.getValue();
    const settingsMenu = currentUser?.data?.menu?.find(
      (item) => item?.url === "global_controls"
    );
    setMenuList(settingsMenu?.permissions);
  }, []);

  useEffect(() => {
    getCustomSectorQuestion();
  }, [viewQuestionList]);

  return (
    <div className="sectorQuestion">
      <div className="d-flex align-items-center justify-content-between mb-2">
        <div className="heading">
          <h4>
            {sectorQuestion &&
              sectorQuestion?.length > 0 &&
              sectorQuestion[0]?.frameworkTitle +
              "/" +
              sectorQuestion[0]?.topicTitle +
              (sectorQuestion[0]?.kpiTitle === undefined
                ? " "
                : +"/" + sectorQuestion[0]?.kpiTitle)}
          </h4>
        </div>
      </div>
      <div className="Introduction">
        <div className="question_section">
          <div
            className="d-flex align-items-center justify-content-between"
            style={{
              background: "#1f9ed1",
              borderBottom: "3px solid #fff",
            }}
          >
            <QuestionTypeTabSection
              setSelectedQuestionType={setSelectedQuestionType}
            />
          </div>
        </div>
      </div>
      {/* <div className="saved_cards border-top">
        <div className="table_f mt-2 table__overflow__style">
          {sectorQuestion?.length > 0 ? (
            <Table striped bordered hover size="sm">
              <thead>
                <tr className="heading_color sticky_header">
                  <th style={{ width: 50, textAlign: "center" }}>Sr.</th>
                  <th>Question Type</th>
                  <th>Question Heading</th>
                  <th>Sector Question</th>
                  <th style={{ width: 65, textAlign: "center" }}>Delete </th>
                </tr>
              </thead>
              <tbody>
                {sectorQuestion &&
                  sectorQuestion?.map((item, key) => (
                    <tr key={key}>
                      <td style={{ textAlign: "center" }}>{key + 1}</td>
                      <td>{item.questionType}</td>
                      <td>{item.heading}</td>
                      <td>{item.title}</td>
                      {item?.isDeletable && (
                        <td
                          className="red"
                          style={{ textAlign: "center" }}
                          onClick={(e) => deleteSectorQuestion(item.id)}
                        >
                          <i
                            className="fas fa-trash-alt"
                            title="Delete"
                            style={{ color: "red" }}
                          ></i>
                        </td>
                      )}
                    </tr>
                  ))}
              </tbody>
            </Table>
          ) : (
            <div className="hstack justify-content-center">
              <img src={NoDataFound} alt="" srcset="" />
            </div>
          )}
        </div>
      </div> */}
    </div>
  );
};
