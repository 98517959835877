import React, { useState } from "react";
import config from "../../config/config.json";
import { apiCall } from "../../_services/apiCall";
import { useHistory } from "react-router-dom";
import { Col, Row } from "react-bootstrap";

const CreateSectorQuestionModal = (prpos) => {
  const {currentFrameworksData,currentTopicData,frameworkData,currentKpiData,currentAddFrameworkQuestionData,addQuestionList} = prpos;
  console.log(addQuestionList)
  const [inputFields, setInputFields] = useState([
    {
      questionType: "",
      questionHeading: "",
      questionTitle: "",
      question_detail: {},
      formula: "",
      graphApplicable: "NO",
    },
  ]);
  const [framework, setFramework] = useState("");
  const [entity, setEntity] = useState("company");
  const [questionType, setQuestionType] = useState("");
  const [readingValue, setReadingValue] = useState("");
  const [frameworks, setFrameworks] = useState("");
  const [topics, setTopics] = useState("");
  const [topic, setTopic] = useState("");
  const [kpis, setKpis] = useState([]);
  const [kpi, setKpi] = useState("");
  const [row, setRow] = useState([""]);
  const [column, setColumn] = useState([""]);
  let companyId = localStorage.getItem("user_temp_id");
  let token = JSON.parse(localStorage.getItem("currentUser"));

  // useEffect(() => {
  //   callApi();
  // }, []);

  const callApi = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.API_URL}getFramework`,
      {},
      { type: "QUESTION" },
      "GET"
    );
    if (isSuccess) {
      setFrameworks(data?.data);
    }
    // axios.get(`${config.API_URL}getFramework?type=QUESTION`,{
    //   headers: {
    //     Authorization: `Bearer ${localStorage.getItem("token")}`,
    //   }
    // }).then((response) =>{
    //     setFrameworks(response?.data?.data);
    // }).catch((error) =>{
    //   console.log(error);
    // })
  };
  const handleEnableGraph = (event, index) => {
    const values = [...inputFields];
    values[index]["graphApplicable"] = event.target.value;
    setInputFields(values);
  };
  const handleFormula = (event, index) => {
    const values = [...inputFields];
    values[index]["formula"] = event.target.value;
    setInputFields(values);
  };
  const handleChange = (event, index) => {
    const values = [...inputFields];

    values[index][event.target.name] = event.target.value;
    if (event.target.name == "questionType") {
      if (event.target.value == "tabular_question") {
        values[index]["question_detail"] = {
          row: [""],
          column: [""],
        };
      } else if (event.target.value == "quantitative_trends") {
        values[index]["question_detail"] = {
          reading_value: [""],
        };
      } else if (event.target.value == "quantitative") {
        values[index]["question_detail"] = {
          mcqs: [""],
        };
      } else if (event.target.value == "") {
        values[index]["question_detail"] = {};
      } else {
        values[index]["question_detail"] = {};
      }
    }
    setInputFields(values);
  };

  // adds new input
  const handleAdd = () => {
    setInputFields([
      ...inputFields,
      {
        questionType: "",
        questionHeading: "",
        questionTitle: "",
        question_detail: {},
        formula: "",
        graphApplicable: "NO",
      },
    ]);
  };

  // removes input
  const handleRemove = (index) => {
    if (inputFields.length !== 1) {
      const values = [...inputFields];
      values.splice(index, 1);
      setInputFields(values);
    }
  };

  const handleChangeFramework = (value) => {
    // value=value.split("-")
    setFramework(value);
    getTopics(value);
  };

  const getTopics = async (frameworkId) => {
    const { isSuccess, data } = await apiCall(
      `${config.API_URL}getTopic`,
      {},
      { type: "QUESTION", framework_id: frameworkId },
      "GET"
    );
    if (isSuccess) {
      setTopics(data.data);
    }
    // axios.get(`${config.API_URL}getTopic?type=QUESTION&framework_id=${frameworkId}`,{
    //   headers: {
    //     Authorization: `Bearer ${localStorage.getItem("token")}`,
    //   }
    // }).then((response) =>{
    //   setTopics(response?.data?.data);
    // }).catch((error) =>{
    //   console.log(error);
    // })
  };
  const getKpis = async (topicId, frameworkId) => {
    console.log(topicId, "topicId");
    // let response =  topicService.getKpisAccordingToFrameworkAndTopics(
    //   topicId
    // );
    const { isSuccess, data } = await apiCall(
      `${config.API_URL}getKpi`,
      {},
      { type: "QUESTION", topic_id: topicId, framework_id: frameworkId },
      "GET"
    );
    if (isSuccess) {
      setKpis(data.data);
    }
    // axios.get(`${config.API_URL}getKpi?type=QUESTION&topic_id=${topicId}&framework_id=${frameworkId}`,{
    //   headers: {
    //     Authorization: `Bearer ${localStorage.getItem("token")}`,
    //   }
    // }).then((response) =>{
    //   setKpis(response?.data?.data);
    //   console.log(response?.data?.data,"topic data");
    // }).catch((error) =>{
    //   console.log(error);
    // })
  };

  const handleChangeTopic = (value) => {
    setTopic(value);
    getKpis(value, framework);
  };

  const handleChangeKpi = (value) => {
    setKpi(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let obj = {};
    // currentFrameworksData,currentTopicData,currentKpiData,currentAddFrameworkQuestionData
    if(currentKpiData)
    {
      obj.frameworkId = currentAddFrameworkQuestionData?.id;
      obj.topicId = currentKpiData?.topicId;
      obj.kpiId = currentKpiData.id;
    }else if(currentTopicData){
      obj.frameworkId = currentTopicData?.frameworkId;
      obj.topicId = currentTopicData?.id;  
    }else{
      obj.frameworkId = currentFrameworksData?.id;
    }
   
    obj.entity = entity;  
    obj.questions = JSON.stringify(inputFields);
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}createCustomSectorQuestion`,
      {},
      obj,
      "POST"
    );
    if (isSuccess) {
    }
    // sectorQuestionService.createSectorQuestion(obj).then((response) =>{
    //   history.push('/questions_framework_wise');
    // });
  };

  const addQuestionDetailLabel = (e, key, index) => {
    e.preventDefault();
    const values = [...inputFields];
    values[index]["question_detail"][key].push("");
    setInputFields(values);
  };

  const removeQuestionDetailLabel = (e, key, i, index) => {
    e.preventDefault();
    const values = [...inputFields];

    let row = values[index]["question_detail"][key];
    if (row.length < 2) {
      return false;
    }
    row.splice(i, 1);
    setInputFields(values);
  };

  const handleQuestionDetailLabelChange = (event, key, i, index) => {
    event.preventDefault();
    const values = [...inputFields];
    values[index]["question_detail"][key][i] = event.target.value;
    setInputFields(values);
  };
  return (
    <>
      <form name="form" onSubmit={(e) => handleSubmit(e)}>
        <div className="business_detail">
          <div className="py-2 px-3">
            <p className="m-0">
              <b>{
              addQuestionList?.frameworkTitle +
                "/" +
                addQuestionList?.topicTitle +
                (addQuestionList?.kpiTitle === undefined
                  ? " "
                  : +"/" + addQuestionList?.kpiTitle)}</b>
            </p>
          </div>

          {/* <Row> */}
          {/* {frameworks && frameworks?.length > 0 ? (
              <Col md={6}>
                <div className="form-group mb-2">
                  <label htmlFor="industryType">Select Framework*</label>
                  <select
                    name="tab_name"
                    onChange={(e) => handleChangeFramework(e.target.value)}
                    className="select_one industrylist"
                  >
                    <option>Select Framework</option>
                    {frameworks &&
                      frameworks.map((item, key) => (
                        <option key={key} value={`${item.id}`}>
                          {item.frameworkTitle}
                        </option>
                      ))}
                  </select>
                </div>
              </Col>
            ) : (
              ""
            )}
            {topics && topics?.length > 0 ? (
              <Col md={6}>
                <div className="form-group mb-2">
                  <label htmlFor="industryType">Select Topic*</label>
                  <select
                    name="tab_name"
                    onChange={(e) => handleChangeTopic(e.target.value)}
                    className="select_one industrylist"
                  >
                    <option>Select Topic</option>
                    {topics &&
                      topics.map((item, key) => (
                        <option key={key} value={`${item.id}`}>
                          {item.title}
                        </option>
                      ))}
                  </select>
                </div>
              </Col>
            ) : (
              ""
            )}
            {kpis && kpis?.length > 0 ? (
              <Col md={6}>
                <div className="form-group mb-2">
                  <label htmlFor="industryType">Select KPI*</label>
                  <select
                    name="tab_name"
                    onChange={(e) => handleChangeKpi(e.target.value)}
                    className="select_one industrylist"
                  >
                    <option>Select KPI</option>
                    {kpis &&
                      kpis.map((item, key) => (
                        <option key={key} value={item.id}>
                          {item.title}
                        </option>
                      ))}
                  </select>
                </div>
              </Col>
            ) : (
              ""
            )} */}
          {inputFields?.map((item, index) => (
            <>
              <div className="Questions__Boxes_forEntity">
                <Row>
                  <Col md={12}>
                    <div className="mb-2">
                      <label htmlFor="industryType">
                        Select Question Type*
                      </label>
                      <select
                        className="select_one industrylist"
                        name="questionType"
                        value={item.questionType}
                        onChange={(event) => handleChange(event, index)}
                      >
                        <option>Select Question Type</option>
                        <option value="qualitative">Qualitative</option>
                        <option value="yes_no" title="">
                          Yes/No
                        </option>
                        <option value="quantitative">Quantitative</option>
                        <option value="tabular_question">
                          Tabular Question
                        </option>
                        <option value="quantitative_trends">
                          Quantitative Trends
                        </option>
                      </select>
                    </div>
                  </Col>
                  {item?.questionType === "qualitative" ||
                  item?.questionType === "yes_no" ||
                  item?.questionType === "quantitative" ||
                  item?.questionType === "tabular_question" ||
                  item?.questionType === "quantitative_trends" ? (
                    <div className="mb-2">
                      <label htmlFor="title">Question Heading</label>
                      <input
                        type="text"
                        name="questionHeading"
                        className="form-control py-2"
                        placeholder="Enter Question Heading or Leave This Options"
                        value={item.questionHeading}
                        onChange={(event) => handleChange(event, index)}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {item.questionType === "quantitative_trends" && (
                    <div className="mb-2">
                      <label htmlFor="title">Formula</label>
                      <input
                        type="text"
                        name="questionHeading"
                        className="form-control py-2"
                        placeholder="Enter Formula"
                        value={item.formula}
                        onChange={(event) => handleFormula(event, index)}
                      />
                    </div>
                  )}
                  {item.questionType === "tabular_question" && (
                    <div className="mb-2">
                      <label htmlFor="industryType">Enable Graph*</label>
                      <select
                        className="select_one industrylist"
                        name="questionType"
                        value={item?.graphApplicable}
                        onChange={(event) => handleEnableGraph(event, index)}
                      >
                        <option value="NO">NO</option>
                        <option value="YES">YES</option>
                      </select>
                    </div>
                  )}
                  {item?.questionType === "qualitative" ||
                  item?.questionType === "yes_no" ||
                  item?.questionType === "quantitative" ||
                  item?.questionType === "tabular_question" ||
                  item?.questionType === "quantitative_trends" ? (
                    <div className="mb-2">
                      <label htmlFor="questionTitle">Sector Question* </label>
                      <textarea
                        type="text"
                        name="questionTitle"
                        className="form-control"
                        placeholder="Write Sector Question title"
                        value={item.questionTitle}
                        onChange={(event) => handleChange(event, index)}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <div>
                    {item.questionType === "quantitative_trends" ? (
                      <>
                        <label htmlFor="industryType">
                          Reading Value Options
                        </label>
                        {item.question_detail?.reading_value &&
                          item.question_detail.reading_value.map(
                            (currElement, i) => {
                              return (
                                <div className="d-flex">
                                  <input
                                    type="text"
                                    className="form-control py-2 mb-2"
                                    value={currElement}
                                    placeholder={`Add Reading value label ${
                                      i + 1
                                    }`}
                                    onChange={(e) =>
                                      handleQuestionDetailLabelChange(
                                        e,
                                        "reading_value",
                                        i,
                                        index
                                      )
                                    }
                                  />
                                </div>
                              );
                            }
                          )}
                      </>
                    ) : (
                      ""
                    )}
                    {item.questionType === "tabular_question" ? (
                      <>
                        <div className="mt-2">
                          <label htmlFor="industryType">Add rows</label>
                          {item.question_detail?.row &&
                            item.question_detail.row.map((currElement, i) => {
                              return (
                                <div
                                  className="d-flex mb-2 justify-content-between"
                                  style={{ gap: "20px" }}
                                >
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={currElement}
                                    placeholder={`add row label ${i + 1}`}
                                    onChange={(e) =>
                                      handleQuestionDetailLabelChange(
                                        e,
                                        "row",
                                        i,
                                        index
                                      )
                                    }
                                  />
                                  <button
                                    className="new_button_style-green"
                                    style={{ width: "60px" }}
                                    onClick={(e) =>
                                      addQuestionDetailLabel(e, "row", index)
                                    }
                                  >
                                    <i
                                      class="fas fa-plus-circle"
                                      style={{ fontSize: "20px" }}
                                    ></i>
                                  </button>
                                  <button
                                    className="new_button_style-red"
                                    style={{ width: "60px" }}
                                    onClick={(e) =>
                                      removeQuestionDetailLabel(
                                        e,
                                        "row",
                                        i,
                                        index
                                      )
                                    }
                                  >
                                    <i
                                      class="fas fa-trash-alt"
                                      style={{ fontSize: "17px" }}
                                    ></i>
                                  </button>
                                </div>
                              );
                            })}
                        </div>
                        <label htmlFor="industryType">Add columns</label>
                        {item.question_detail?.column &&
                          item.question_detail?.column.map((currElement, i) => {
                            return (
                              <div
                                className="d-flex mb-2 justify-content-between"
                                style={{ gap: "20px" }}
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  value={currElement}
                                  placeholder={`add column label ${i + 1}`}
                                  onChange={(e) =>
                                    handleQuestionDetailLabelChange(
                                      e,
                                      "column",
                                      i,
                                      index
                                    )
                                  }
                                />
                                <button
                                  className="new_button_style-green"
                                  style={{ width: "60px" }}
                                  onClick={(e) =>
                                    addQuestionDetailLabel(e, "column", index)
                                  }
                                >
                                  <i
                                    class="fas fa-plus-circle"
                                    style={{ fontSize: "20px" }}
                                  ></i>
                                </button>
                                <button
                                  className="new_button_style-red"
                                  style={{ width: "60px" }}
                                  onClick={(e) =>
                                    removeQuestionDetailLabel(
                                      e,
                                      "column",
                                      i,
                                      index
                                    )
                                  }
                                >
                                  <i
                                    class="fas fa-trash-alt"
                                    style={{ fontSize: "17px" }}
                                  ></i>
                                </button>
                              </div>
                            );
                          })}
                      </>
                    ) : (
                      ""
                    )}
                    {inputFields.length >= 2 ? (
                      <div>
                        <button
                          type="button"
                          className="remove new_button_style-red"
                          onClick={(e) => handleRemove(index)}
                          title="Delete"
                        >
                          X
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </Row>
              </div>
            </>
          ))}
          {/* </Row> */}
        </div>
        <div className="d-flex Add__Questions_btns justify-content-between">
          <div className="global_link">
            <button
              type="button"
              className="new_button_style add"
              onClick={(e) => handleAdd()}
            >
              ADD More
            </button>
          </div>
          <div className="global_link">
            <button type="submit" className="new_button_style">
              Save
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default CreateSectorQuestionModal;
