import React, { useState, useEffect } from "react";
import config from "../../config/config.json";
import {
  Accordion,
  Button,
  Col,
  Modal,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import "./working_progress.css";
import { apiCall } from "../../_services/apiCall";
import Loader from "../loader/Loader";

const LocationManagement = ({ tab, userPermissionList }) => {
  console.log(userPermissionList, "LocationManagement");
  const [meterDataList, setMeterDataList] = useState([]);
  const [filterListValue, setFilterListValue] = useState([]);
  const [address, setAddress] = useState("");
  const [showSkelton, setshowSkelton] = useState(false);
  const [mode, setMode] = useState("create");
  const [showAddLocation, setAddLocationShow] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [isError, setIsError] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [deleteLocationPopup, setDeleteLocationPopup] = useState(false);
  const closeDeleteLocationPopup = () => setDeleteLocationPopup(false);
  const [activeLocationTab, setActiveLocationTab] = useState("");

  const handleKey = async (type) => {
    if (type === "LocationTab") {
      setActiveLocationTab((prevKey) => (prevKey === "0" ? null : "0"));
    }
  };

  const showDeleteLocationPopup = (id) => {
    setSelectedId(id);
    setDeleteLocationPopup(true);
  };

  const handleAddLocationShow = () => {
    setMode("create");
    setAddLocationShow(true);
  };

  const handleEditLocationShow = (id, location) => {
    setMode("edit");
    setSelectedId(id);
    setAddress(location.area);
    setSelectedLocation(location);
    setAddLocationShow(true);
  };

  const handleClose = () => {
    setMode("create");
    setAddLocationShow(false);
  };

  const [selectedLocation, setSelectedLocation] = useState({
    area: "",
    city: "",
    state: "",
    country: "",
    zipCode: "",
  });
  const userId = JSON.parse(localStorage.getItem("user_temp_id"));

  const createSource = async (id, e) => {
    setSpinner(true);
    const { isSuccess, data, error } = await apiCall(
      config.POSTLOGIN_API_URL_COMPANY + `createSource`,
      {},
      {
        location: JSON.stringify(selectedLocation),
      },
      "POST"
    );
    setSpinner(false);
    if (isSuccess) {
      getSource();
      setAddress("");
      setSelectedLocation({
        area: "",
        city: "",
        state: "",
        country: "",
        zipCode: "",
      });
      handleClose();
    }
  };

  const getSource = async () => {
    setshowSkelton(true);
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getSource`,
      {},
      { userId: userId },
      "GET"
    );
    if (isSuccess) {
      setshowSkelton(false);
      setMeterDataList(data?.data?.reverse());
      setFilterListValue(data?.data);
    }
  };

  const updateSource = async (e) => {
    setSpinner(true);
    const { isSuccess, data, error } = await apiCall(
      config.POSTLOGIN_API_URL_COMPANY + `updateSource`,
      {},
      {
        location: JSON.stringify(selectedLocation),
        id: selectedId,
      },
      "POST"
    );
    setSpinner(false);
    if (isSuccess) {
      getSource();
      setAddress("");
      handleClose();
      setSelectedLocation({
        area: "",
        city: "",
        state: "",
        country: "",
        zipCode: "",
      });
      handleClose();
    }
  };
  const deleteSource = async () => {
    const { isSuccess, data, error } = await apiCall(
      config.POSTLOGIN_API_URL_COMPANY + `deleteSource`,
      {},
      { id: selectedId },
      "POST"
    );
    if (isSuccess) {
      getSource();
      closeDeleteLocationPopup();
    }
  };

  const handleChange = (newAddress) => {
    setAddress(newAddress);
  };

  const handleSelect = async (selectedAddress) => {
    try {
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]);
      const addressComponents = results[0].address_components;
      const localityIndex = addressComponents.findIndex((component) =>
        component.types.includes("locality")
      );
      const area = addressComponents
        .slice(0, localityIndex)
        .map((component) => component.long_name)
        .join(", ");
      const city =
        addressComponents.find((component) =>
          component.types.includes("locality")
        )?.long_name || "";

      const state =
        addressComponents.find((component) =>
          component.types.includes("administrative_area_level_1")
        )?.long_name || "";
      const country =
        addressComponents.find((component) =>
          component.types.includes("country")
        )?.long_name || "";
      const zipCode =
        addressComponents.find((component) =>
          component.types.includes("postal_code")
        )?.long_name || "";
      setAddress(area);
      setSelectedLocation({
        area,
        city,
        state,
        country,
        zipCode,
      });
    } catch (error) {
      console.error("Error selecting location", error);
    }
  };
  const handleManualInputChange = (field, value) => {
    setSelectedLocation((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };
  const handleSearch = (searchTerm) => {
    const trimmedSearchTerm = searchTerm.trim().toLowerCase();
    if (trimmedSearchTerm === "") {
      setFilterListValue([...meterDataList]);
    } else {
      const filteredResult = meterDataList.filter((item) => {
        const lowerCaseCompanyName = (item.company_name || "").toLowerCase();
        const location = item.location || {};
        if (location && typeof location === "object") {
          return (
            lowerCaseCompanyName.includes(trimmedSearchTerm) ||
            (location.area || "").toLowerCase().includes(trimmedSearchTerm) ||
            (location.city || "").toLowerCase().includes(trimmedSearchTerm) ||
            (location.state || "").toLowerCase().includes(trimmedSearchTerm) ||
            (location.country || "")
              .toLowerCase()
              .includes(trimmedSearchTerm) ||
            (location.zipCode || "").toLowerCase().includes(trimmedSearchTerm)
          );
        }
        return false;
      });
      setFilterListValue(filteredResult);
    }
  };

  useEffect(() => {
    getSource();
  }, [tab]);
  return (
    <>
      {" "}
      <div className="Introduction framwork_2">
        <section className="forms">
          <div className="d-flex align-items-center justify-content-between">
            <div className="directly p-0 hstack gap-2">
              <input
                type="search"
                className="form-control w-100"
                placeholder="Search"
                name="search"
                onChange={(e) => handleSearch(e.target.value)}
              />
              {userPermissionList.some(
                (permission) =>
                  permission.permissionCode === "CREATE" && permission.checked
              ) && (
                <Button variant="info" onClick={handleAddLocationShow}>
                  Add Location
                </Button>
              )}
            </div>
          </div>
          {!showSkelton ? (
            <div className="table_setting">
              <Table striped bordered>
                <thead>
                  <tr style={{ background: "#ccc" }}>
                    <th style={{ width: 60 }}>Sr. No</th>

                    <th>Corporate Headquarters Address</th>
                  </tr>
                </thead>
              </Table>
              {!showSkelton ? (
                <tbody>
                  {filterListValue && filterListValue?.length > 0 ? (
                    filterListValue?.map((data, key) => {
                      return (
                        data &&
                        data?.head_Office === true && (
                          <tr key={key}>
                            <td style={{ width: 60 }}>1</td>
                            <td>
                              {" "}
                              {data.location.area}, {data.location.city},{" "}
                              {data.location.state}, {data.location.country} -{" "}
                              {data.location.zipCode}
                            </td>
                          </tr>
                        )
                      );
                    })
                  ) : (
                    <tr>
                      <td>--</td>
                      <td>No Data Found</td>
                      <td>--</td>
                    </tr>
                  )}
                </tbody>
              ) : (
                <Loader />
              )}
              <hr className="line" />
              {filterListValue && filterListValue?.length > 0 ? (
                <div className="table_setting">
                  <Table striped bordered>
                    <thead>
                      <tr style={{ background: "#ccc" }}>
                        <th style={{ width: 60 }}>Sr. No</th>
                        <th>Regional Office Address</th>
                        <th style={{ width: 60 }}>Action</th>
                      </tr>
                    </thead>
                    {!showSkelton ? (
                      <tbody>
                        {filterListValue && filterListValue?.length > 0 ? (
                          filterListValue?.map((data, key) => {
                            return (
                              data &&
                              data?.head_Office === false && (
                                <tr key={key}>
                                  <td>{key + 1}</td>
                                  <td>
                                    {" "}
                                    {data.location.area}, {data.location.city},{" "}
                                    {data.location.state},{" "}
                                    {data.location.country} -{" "}
                                    {data.location.zipCode}
                                  </td>
                                  <td className="d-flex align-items-center justify-content-center gap-1">
                                    {userPermissionList.some(
                                      (permission) =>
                                        permission.permissionCode === "EDIT" &&
                                        permission.checked
                                    ) && (
                                      <i
                                        className="fas fa-edit"
                                        title="Edit Location"
                                        onClick={() =>
                                          handleEditLocationShow(
                                            data?.id,
                                            data?.location
                                          )
                                        }
                                      ></i>
                                    )}
                                    &nbsp;&nbsp;
                                    {userPermissionList.some(
                                      (permission) =>
                                        permission.permissionCode ===
                                          "DELETE" && permission.checked
                                    ) && (
                                      <i
                                        className="fas fa-trash-alt"
                                        style={{ color: "red" }}
                                        title="Delete Location"
                                        onClick={() => {
                                          showDeleteLocationPopup(data?.id);
                                        }}
                                      ></i>
                                    )}
                                  </td>
                                </tr>
                              )
                            );
                          })
                        ) : (
                          <tr>
                            <td>--</td>
                            <td>No Data Found</td>
                            <td>--</td>
                          </tr>
                        )}
                      </tbody>
                    ) : (
                      <Loader />
                    )}
                  </Table>
                </div>
              ) : (
                <div className="hstack justify-content-center">
                  <img src={""} alt="" srcset="" />
                </div>
              )}
              {/* <Table striped bordered className="m-0">
                <thead>
                  <tr style={{ background: "#ccc" }}>
                    <th>Regional Office Address</th>
                  </tr>
                </thead>
              </Table>
              <div className="access__section">
                <Accordion activeKey={activeLocationTab}>
                  {filterListValue && filterListValue?.length > 0
                    ? filterListValue.map((data) => {
                        return (
                          data &&
                          data?.head_Office === false && (
                            <Accordion.Item eventKey="0">
                              <Accordion.Header
                                onClick={() => handleKey("LocationTab")}
                              >
                                {data && data?.company_name}
                              </Accordion.Header>
                              {activeLocationTab && (
                                <div className="edit__delete__icon">
                                  {data && data?.is_deletable && (
                                    <div className="d-flex align-items-center gap-3">
                                      <>
                                        {userPermissionList.some(
                                          (permission) =>
                                            permission.permissionCode ===
                                              "EDIT" && permission.checked
                                        ) && (
                                          <div
                                            onClick={() =>
                                              handleEditLocationShow(
                                                data?.id,
                                                data?.location
                                              )
                                            }
                                          >
                                            <i
                                              class="far fa-edit"
                                              title="Edit Location"
                                            ></i>
                                          </div>
                                        )}
                                      </>
                                      {userPermissionList.some(
                                        (permission) =>
                                          permission.permissionCode ===
                                            "DELETE" && permission.checked
                                      ) && (
                                        <div
                                          onClick={() => {
                                            showDeleteLocationPopup(data?.id);
                                          }}
                                        >
                                          <i
                                            class="fas fa-trash-alt"
                                            style={{ color: "red" }}
                                            title="Delete Location"
                                          ></i>
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              )}
                              <Accordion.Body>
                                <div className="border_box">
                                  {data.location.area}, {data.location.city},{" "}
                                  {data.location.state}, {data.location.country}{" "}
                                  - {data.location.zipCode}
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                          )
                        );
                      })
                    : ""}
                </Accordion>
              </div> */}
            </div>
          ) : (
            <Loader />
          )}
        </section>
      </div>
      {/* Add Location ---------------------- */}
      <Modal show={showAddLocation} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {mode === "create" ? "Create" : "Update"} Address
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12}>
              <PlacesAutocomplete
                value={address}
                onChange={handleChange}
                onSelect={handleSelect}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div>
                    <input
                      {...getInputProps({
                        placeholder: "Type your location... *",
                        className: "location-search-input w-100 mb-2",
                      })}
                      required
                    />
                    <div className="autocomplete-dropdown-container">
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion) => (
                        <div
                          {...getSuggestionItemProps(suggestion)}
                          key={suggestion.placeId}
                        >
                          {suggestion.description}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            </Col>
            <Col md={6}>
              <input
                className="w-100 mb-2"
                placeholder="Enter City"
                type="text"
                readOnly
                value={selectedLocation.city}
                onChange={(e) =>
                  handleManualInputChange("city", e.target.value)
                }
              />
            </Col>
            <Col md={6}>
              <input
                type="text"
                className="w-100 mb-2"
                placeholder="Enter State"
                readOnly
                value={selectedLocation.state}
                onChange={(e) =>
                  handleManualInputChange("state", e.target.value)
                }
              />
            </Col>
            <Col md={6}>
              <input
                type="text"
                className="w-100 mb-2"
                placeholder="Enter City"
                readOnly
                value={selectedLocation.country}
                onChange={(e) =>
                  handleManualInputChange("country", e.target.value)
                }
              />
            </Col>
            <Col md={6}>
              <input
                type="text"
                className="w-100 mb-2"
                placeholder="Enter Zip Code"
                readOnly
                value={selectedLocation.zipCode}
                onChange={(e) =>
                  handleManualInputChange("zipCode", e.target.value)
                }
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {spinner ? (
            <Button variant="info" disabled>
              <Spinner animation="border" />{" "}
              {mode === "create" ? "Creating" : "Updating"}
            </Button>
          ) : (
            <Button
              variant="info"
              disabled={isError}
              type="submit"
              onClick={mode === "create" ? createSource : updateSource}
            >
              {mode === "create" ? "Create Location" : "Update Location"}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      <Modal show={deleteLocationPopup} onHide={closeDeleteLocationPopup}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Are you sure you want to delete?</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-danger"
            type="submit"
            onClick={() => deleteSource()}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default LocationManagement;
