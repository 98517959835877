import React, { useEffect, useState } from "react";
import Sidebar from "../sidebar/sidebar";
import Header from "../header/header";
import "./settings.module.css";
import { PermissionMenuContext } from "../../contextApi/permissionBasedMenuContext";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "./settings.module.css";
import Profile from "./Profile";
import Billing from "./Billing";
import SubUsers from "./SubUsers";
import Generator from "./Generator";
import AccessManagement from "./AccessManagement";
import LocationManagement from "./Location";
import FlowCharts from "../flow_charts/FlowCharts";
import DesignationManagement from "./DesignationManagement";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Settings = ({ location }) => {
  const [tab, setTab] = useState("profile");
  const [menuList, setMenuList] = useState([]);

  const handleSelectTab = (eventKey) => {
    if (eventKey === "billing") setTab("billing");
    if (eventKey === "profile") setTab("profile");
    if (eventKey === "subaccounts") setTab("subaccounts");
    if (eventKey === "location") setTab("location");
    if (eventKey === "process") setTab("process");
    if (eventKey === "permission") setTab("permission");
    if (eventKey === "chart") setTab("chart");
    if (eventKey === "designation") setTab("designation");
  };

  useEffect(() => {
    const settingsMenu = JSON.parse(localStorage.getItem("menu"));
    const settingsObject = settingsMenu.find(
      (item) => item.caption === "Settings"
    );
    const settingsSubMenu = settingsObject ? settingsObject.sub_menu : [];
    setMenuList(settingsSubMenu);
  }, [tab]);
  return (
    <PermissionMenuContext.Consumer>
      {({ userPermissionList }) => (
        <div>
          <Header />
          <Sidebar />
          <div className="main_wrapper">
            <div className="inner_wraapper pb-0">
              <Tabs
                defaultActiveKey="profile"
                id="uncontrolled-tab-example"
                className="Setting_tabs"
                onSelect={(eventKey) => handleSelectTab(eventKey)}
              >
                {menuList.find(
                  (item) => item.caption === "Profile Management"
                ) && (
                  <Tab eventKey="profile" title="Profile">
                    {tab === "profile" && (
                      <Profile
                        userPermissionList={
                          menuList.find(
                            (item) => item.caption === "Profile Management"
                          )?.permissions || <Skeleton />
                        }
                        tab={tab}
                      />
                    )}
                  </Tab>
                )}

                {menuList.find(
                  (item) => item.caption === "Billing Management"
                ) && (
                  <Tab eventKey="billing" title="Billing">
                    {tab === "billing" && (
                      <Billing
                        tab={tab}
                        userPermissionList={
                          menuList.find(
                            (item) => item.caption === "Billing Management"
                          )?.permissions || <Skeleton />
                        }
                      />
                    )}
                  </Tab>
                )}

                {menuList.find(
                  (item) => item.caption === "User Management"
                ) && (
                  <Tab eventKey="subaccounts" title="User">
                    {tab === "subaccounts" && (
                      <SubUsers
                        tab={tab}
                        userPermissionList={menuList.find(
                          (item) => item.caption === "User Management"
                        )?.permissions || <Skeleton />}
                      />
                    )}
                  </Tab>
                )}
                {menuList.find(
                  (item) => item.caption === "Location Management"
                ) && (
                  <Tab eventKey="location" title="Location">
                  {tab === "location" && (
                    <LocationManagement
                      tab={tab}
                      userPermissionList={
                        menuList.find(
                          (item) => item.caption === "Location Management"
                        )?.permissions || <Skeleton />
                      }
                    />
                  )}
                </Tab>
                )}
                {menuList.find(
                  (item) => item.caption === "Process Management"
                ) && (
                  <Tab eventKey="process" title="Process">
                  {tab === "process" && (
                    <Generator
                      tab={tab} 
                      userPermissionList={
                        menuList.find(
                          (item) => item.caption === "Process Management"
                        )?.permissions || <Skeleton />
                      }
                    />
                  )}
                </Tab>
                )}
                {menuList.find(
                  (item) => item.caption === "Permission Management"
                ) && (
                  <Tab eventKey="permission" title="Permission">
                  {tab === "permission" && (
                    <AccessManagement
                      tab={tab}
                      userPermissionList={
                        menuList.find(
                          (item) => item.caption === "Permission Management"
                        )?.permissions || <Skeleton />
                      }
                    />
                  )}
                </Tab>
                )}
                {menuList.find(
                  (item) => item.caption === "Create Org Chart"
                ) && (
                  <Tab eventKey="chart" title="Chart">
                  {tab === "chart" && (
                    <FlowCharts
                      tab={tab}
                      userPermissionList={
                        menuList.find(
                          (item) => item.caption === "Create Org Chart"
                        )?.permissions || <Skeleton />
                      }
                    />
                  )}
                </Tab>
                )}
                {menuList.find(
                  (item) => item.caption === "Designation Management"
                ) && (
                  <Tab eventKey="designation" title="Designation">
                  {tab === "designation" && (
                    <DesignationManagement
                      tab={tab}
                      userPermissionList={
                        menuList.find(
                          (item) => item.caption === "Designation Management"
                        )?.permissions || <Skeleton />
                      }
                    />
                  )}
                </Tab>
                )}
              </Tabs>
            </div>
          </div>
        </div>
      )}
    </PermissionMenuContext.Consumer>
  );
};

export default Settings;
