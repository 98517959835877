import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import NotAnswered from "../../../img/sector/notAnswer.png";
import ConvertTimeStamp from "./../../../utils/ConvertTimeStamp";
import QuestionTypeTabSection from "../../Company Sub Admin/Component/Sector Questions/QuestionTypeTabSection";

const AuditCard = ({ audit_data, index, setAuditAnswer, selectedRow }) => {
  const history = useHistory();

  let dateTime = audit_data?.answer_details?.updatedAt
    ? ConvertTimeStamp(audit_data?.answer_details?.updatedAt)
    : audit_data?.answer_details?.answered_at
      ? ConvertTimeStamp(audit_data.answer_details.answered_at)
      : ["No", "Date Given."];
  return (
    <tr
      style={{
        backgroundColor: (selectedRow === audit_data?.questionId) ? "#1f9ed1" : "",
      }}
    >
      <td>
        <span>{index}</span>
      </td>
      <td
        onClick={() => {
          setAuditAnswer(audit_data);
        }}
      >
        <span>{audit_data?.title}</span>
      </td>
      <td style={{ width: 42 }}>
        <img
          className="notAnsered_question"
          src={NotAnswered}
          alt="Not Answered"
          srcSet=""
          title="Not Answered"
        />
      </td>
    </tr>
  );
};

export default AuditCard;
