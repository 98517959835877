import React, { useState } from "react";
import Header from "../../../header/header";
import Sidebar from "../../../sidebar/sidebar";
import { NavLink, useLocation } from "react-router-dom";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { apiCall } from "../../../../_services/apiCall";
import config from "../../../../config/config.json";

const InvitedSupplierManagement = () => {
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const sendInvite = async (id, e) => {
    // setUpdateLoader(true);
    const { isSuccess, data, error } = await apiCall(
      config.POSTLOGIN_API_URL_COMPANY + `inviteSupplier`,
      {},
      {      
        email: email,
        companyName:companyName
      },
      "POST"
    );
    // setUpdateLoader(false);
    if (isSuccess) {
      // getSubUser();
      // setAddNewUserPopup(false);
     
    }
  };
  return (
    <>
      <Sidebar dataFromParent={location?.pathname} />
      <Header />
      <div className="main_wrapper">
        <section className="inner_wraapper">
          <div className="hol_rell">
            <div className="steps-form">
              <div className="steps-row setup-panel">
                <div className="tabs-top setting_admin my-0">
                  <ul>
                    <li>
                      <NavLink to="/supplier-management"> Registerd Suppliers</NavLink>
                    </li>
                    <li>
                      <NavLink to="/invited-suppliers" className="activee"> Invited Suppliers </NavLink>
                    </li>
                  </ul>
                </div>
                <Button variant="info" className="mx-2" onClick={handleShow}>
                  Add Suppliers
                </Button>
              </div>
            </div>
          </div>
          <div className="Introduction history__sections">
            <div className="question_section">
              <Table striped hover bordered className="m-0" style={{ cursor: "pointer" }}>
                <thead>
                  <tr className="fixed_tr_section">
                    <th style={{ width: 55 }}>Sr No</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Company Name</th>
                    <th>City</th>
                    <th style={{ width: 100, textAlign: "center" }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>Roop Chandra</td>
                    <td>roop@galaxycorp.com</td>
                    <td>+91 1234567890</td>
                    <td>Galax Corp</td>
                    <td>Bengaluru</td>
                    <td>
                      <div className="hstack gap-3 justify-content-center">
                        <i class="fas fa-retweet" title="Resend"></i>
                        <i class="fas fa-window-close" title="Cancel" style={{ color: "red" }}></i>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>Roop Chandra</td>
                    <td>roop@galaxycorp.com</td>
                    <td>+91 1234567890</td>
                    <td>Galax Corp</td>
                    <td>Bengaluru</td>
                    <td>
                      <div className="hstack gap-3 justify-content-center">
                        <i class="fas fa-retweet" title="Resend"></i>
                        <i class="fas fa-window-close" title="Cancel" style={{ color: "red" }}></i>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </section>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Supplier</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            className="mb-2"
            required
            type="email"
            placeholder="Email Id"
            onChange={(e)=>setEmail(e.target.value)}
          />
          <Form.Control
            required
            type="text"
            placeholder="Company Name"
            onChange={(e)=>setCompanyName(e.target.value)}

          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-danger" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="info" onClick={sendInvite}>Add Supplier</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default InvitedSupplierManagement;
