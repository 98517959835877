import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const RadioBarChart = (props) => {
  console.log(props.chartData, "props");
  const [chartData, setChartData] = useState({
    series: [10, 10, 10, 10],
    options: {
      chart: {
        height: 800,
        type: "radialBar",
      },
      // title: {
      //   text: "Radio Bar View",
      // },
      plotOptions: {
        radialBar: {
          dataLabels: {
            name: {
              fontSize: "22px",
            },
            value: {
              fontSize: "16px",
            },
            total: {
              show: true,
              label: "Total",
              formatter: function (w) {
                
                return 100;
              },
            },
          },
        },
      },
      labels: [
        "qualitative",
        "quantitative",
        "yes_no",
        "tabular_question",
        "quantitative_trends",
      ],
    },
  }); 
  
  useEffect(() => {
    if (props?.chartData)
    {
      setChartData(props?.chartData);
    }
  }, [props]);

  return (
    <div id="chart">
      {console.log(chartData, "chartData")}
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="radialBar"
        height={345}
      />
    </div>
  );
};

export default RadioBarChart;
