import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Modal } from "react-bootstrap";
import { BarChart, ColumnChart, RadioBarChart } from "./Chart";
import { RadioBarChartData, ColumnChartData, BarChartData } from "./chartData";
import config from "../../../../config/config.json";
import { apiCall } from "../../../../_services/apiCall";
import FilterDashoard from "../../../../img/sector/filter.png";
import Multiselect from "multiselect-react-dropdown";

const FrameworkComponent = () => {
  const multiselectRef = useRef(null);
  const [filter, setFilter] = useState(false);
  const [seriesDataAllType, setSeriesDataAllType] = useState([]);
  const [levelDataAllType, setlevelDataAllType] = useState([]);
  const [radioBarGraphData, setRadioBarGraphData] = useState([]);
  const [responseData, setResponseData] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState();
  const [selectedFramework, setSelectedFramework] = useState([]);
  const [Framework, setFramework] = useState([]);
  const [selectedChartType, setSelectedChartType] = useState("RadioBarChart");
  const [chartType, setChartType] = useState("");
  const [allZero, setAllZero] = useState(false); 
  const handleFilterClose = () => setFilter(false);
  const onRemoveHandler = (removedItem) => {
    const updatedSelectedId = [...selectedFramework];
    const indexToRemove = updatedSelectedId.findIndex(
      (item) => item === removedItem
    );
    if (indexToRemove !== -1) {
      updatedSelectedId.splice(indexToRemove, 1);
      setSelectedFramework(updatedSelectedId);
    }
  };

  const onSelectHandler = (selectedItems) => {
    const selectedItemValues = selectedItems.map((item) => item?.full_name);
    setSelectedFramework(selectedItemValues);
  };

  const myDisclosureProgress = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}frameworkProgress`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      const responseData = data.data?.frameworkResults;
      setResponseData(responseData);
      const categories = Object.keys(responseData);
      console.log(categories, "categoriescategories");
      const setMyDisclosureProgress = (key) =>
        categories.map((category) => parseFloat(responseData[category][key]));
      setlevelDataAllType(categories);
      let frameworkOptions = data?.data?.frameworkOptions;
      setFramework(frameworkOptions);
      const myDisclosureProgressData = {
        answered: setMyDisclosureProgress("answerIds"),
        accepted: setMyDisclosureProgress("accptedAnswerIds"),
        rejected: setMyDisclosureProgress("rejectedAnswerIds"),
        notResponded: setMyDisclosureProgress("notRespondedIds"),
      };
      const allZeroes = myDisclosureProgressData.answered.every(value => value === 0);
      setAllZero(allZeroes);
      setSeriesDataAllType(myDisclosureProgressData);
      const GraphData = await RadioBarChartData(
        categories.slice(0, 5),
        myDisclosureProgressData.answered.slice(0, 5)
      );
      setRadioBarGraphData(GraphData);
      const filter = data?.data?.filter;
      const filteredArray = frameworkOptions.filter((obj) =>
        filter?.frameworkIds.includes(obj.id)
      );
      setSelectedFramework(filteredArray);
      setSelectedStatus(filter?.Status);
    }
  };

  const handleApplyFilter = async () => {
    const renderChart = async () => {
      switch (selectedChartType) {
        case "BarChart":
          return (
            <BarChart
              chartData={await BarChartData(levelDataAllType, [
                {
                  name: selectedStatus,
                  data: seriesDataAllType[selectedStatus],
                },
              ])}
            />
          );
        case "ColumnChart":
          return (
            <ColumnChart
              chartData={await ColumnChartData(levelDataAllType, [
                {
                  name: selectedStatus,
                  data: seriesDataAllType[selectedStatus],
                },
              ])}
            />
          );
        case "RadioBarChart":
        default:
          return (
            <RadioBarChart
              chartData={await RadioBarChartData(
                levelDataAllType,
                seriesDataAllType[selectedStatus]
              )}
            />
          );
      }
    };
    setChartType(await renderChart());
    setFilter(false);
    handleFilterClose();
  };

  useEffect(() => {
    myDisclosureProgress();
  }, []);
  return (
    <>
      <Col md={12}>
        <div className="esg_score_title d-flex align-items-center justify-content-between">
          <p className="m-0">Framework Progress</p>
         { !allZero && <button
            className="btn btn esgDownloadReporting "
            onClick={() => {
              setFilter(true);
            }}
          >
            <img
              src={FilterDashoard}
              alt=""
              title="Framework Progress Filter"
            />
          </button>}
        </div>
        <div className="p-0">
          <div className="main_text">
            {chartType !== "" ? (
              chartType
            ) : (
              <RadioBarChart chartData={radioBarGraphData} />
            )}
          </div>
        </div>
      </Col>
      <Modal size="md" show={filter} onHide={handleFilterClose}>
        <Modal.Header closeButton>
          <Form.Label className="align-items-center m-0">
            <strong>Framework Progress Filter</strong>
          </Form.Label>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="formStatusType">
            <Form.Label>Select Framework Name</Form.Label>
            <Multiselect
              placeholder="Select Framework"
              displayValue="title"
              className="multi_select_dropdown w-100"
              options={Framework}
              selectedValues={selectedFramework}
              ref={multiselectRef}
              onRemove={(removedItem) => {
                onRemoveHandler(removedItem);
              }}
              onSelect={(selectedItems) => {
                onSelectHandler(selectedItems);
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formStatusType">
            <Form.Label>Select Status Type</Form.Label>
            <Form.Select
              aria-label="Select Status Type"
              onChange={(e) => setSelectedStatus(e.target.value)}
              value={selectedStatus}
            >
              <option value="answered">Answered</option>
              <option value="accepted">Accepted</option>
              <option value="rejected">Rejected</option>
              <option value="notResponded">Not Responded</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formChartType">
            <Form.Label>Select Chart Type</Form.Label>
            <Form.Select
              aria-label="Select Chart Type"
              onChange={(e) => setSelectedChartType(e.target.value)}
              value={selectedChartType}
            >
              <option value="RadioBarChart">Radio Bar Chart</option>
              <option value="BarChart">Bar Chart</option>
              <option value="ColumnChart">Column Chart</option>
            </Form.Select>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <button className="new_button_style" onClick={handleApplyFilter}>
            Apply
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FrameworkComponent;
