/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { Component, useContext } from "react";
import config from "../../config/config.json";
import { apiCall } from "../../_services/apiCall";
import viewCross from "../../img/eye-icon-cross.png";
import view from "../../img/eye-icon.png";
import { ROLE_ID_MAPPING } from "../../_constants/constants";
import {
  Accordion,
  Button,
  Col,
  Modal,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { ProfilePicContext } from "../../contextApi/profileContext";
import { history } from "../../_helpers/history";
import { authenticationService } from "../../_services/authentication";

const ProfilePics =
  "https://res.cloudinary.com/dmklsntsw/image/upload/v1658480882/dummyPic.75a04487_fqfqey.png";
const baseURL = config.baseURL;

export default class Profile extends Component {
  static contextType = ProfilePicContext;
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      editMode: false,
      isLoaded: false,
      loading: false,
      firstName: "",
      lastName: "",
      location: "",
      resetPasswordEmail: "",
      email: "",
      type: "password",
      type1: "password",
      passwordMatch: false,
      businessNumber: "",
      sectorofInterests: "",
      userCategory: "",
      register_company_name: "",
      main_country_of_operations: "",
      company_industry: "",
      logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSsSBgd6D0Z4z-80aTULxvtHFwQ9ayNTYqmcQ&usqp=CAU",
      position: "",
      no_of_users: "",
      password: "",
      confirmPassword: "",
      id: "",
      allCountriesItems: [],
      permissionMenu: [],
      titleOrPositionsItems: [],
      showChangePasswordPopup: false,
    };
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleUpdateProfile = this.handleUpdateProfile.bind(this);
    this.handleUpdatPosition = this.handleUpdatPosition.bind(this);
    this.showHide = this.showHide.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
  }

  handleChangePasswordClose = () => {
    this.setState({
      showChangePasswordPopup: false,
      password: "",
      confirmPassword: "",
      passwordMatch: false,
    });
  };

  handleChangePasswordShow = () => {
    this.setState({ showChangePasswordPopup: true });
  };
  // setContextValue = (newValue) => {
  //   this.context.setValue(newValue);
  // };
  async uploadProfilePicture(url) {
    const body = {
      filename: url,
    };
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}uploadProfilePictureOrAttachment`,
      {},
      body,
      "POST"
    );
    if (isSuccess) {
      let beforeThis = data.data.split(":")[0];
      // this.setContextValue(data?.data);
      // setProfilePicture();
      this.setState({
        logo: beforeThis != "https" ? ProfilePics : data?.data,
      });
    }
  }

  onFileChange = (event) => {
    const timestamp = new Date().getTime();
    const fileName = `${timestamp}_${event.target.files[0]?.name}`;
    const formData = new FormData();
    formData.append("file", event.target.files[0]);
    formData.append("fileName", fileName);
    formData.append("filePath", "yasil/");

    var requestOptions = {
      header: {
        "Content-Type": "multipart/form-data",
      },
      method: "POST",
      body: formData,
      redirect: "follow",
    };
    fetch(
      `${
        config.AUTH_API_URL_COMPANY
      }uploadFile?current_role=${localStorage.getItem("role")}`,
      requestOptions
    )
      .then((response) => response.text())
      .then(async (result) => {
        let url = JSON.parse(result);
        this.uploadProfilePicture(url?.url);
      })
      .catch((error) => console.log("error", error));
  };

  async handleConfirmPassword(e, password) {
    if (password === "password") {
      this.setState({ password: e.target.value });
    } else {
      this.setState({ confirmPassword: e.target.value });
    }
    if (this.state.confirmPassword) {
      const camparevallue =
        password === "password"
          ? this.state.confirmPassword
          : this.state.password;
      if (camparevallue === e.target.value) {
        this.setState({ passwordMatch: true });
      } else {
        this.setState({ passwordMatch: false });
      }
    }
    if (password !== "password") {
      if (this.state.password === e.target.value) {
        this.setState({ passwordMatch: true });
      } else {
        this.setState({ passwordMatch: false });
      }
    }
  }
  logout() {
    authenticationService.logout();
    history.push("/#/");
    localStorage.clear();
    window.location.reload();
  }
  async handlePasswordChange() {
    this.setState({ loading: true });
    if (this.state.passwordMatch) {
      const { isSuccess, data } = await apiCall(
        config.POSTLOGIN_API_URL_COMPANY + "changePassword",
        {},
        {
          password: this.state.password,
        },
        "POST"
      );
      this.setState({ loading: false });
      if (isSuccess) {
        this.logout();
      }
    }
  }
  async updateProfileForUser(data) {
    const { isSuccess } = await apiCall(
      config.POSTLOGIN_API_URL_COMPANY + "updateUserProfile",
      {},
      {
        data,
      },
      "POST"
    );
    if (isSuccess) {
    }
  }
  async handleUpdateProfile(event) {
    const inputValue = event.target.value;
    const spaceIndex = inputValue.indexOf(" ");
    if (spaceIndex !== -1) {
      this.setState({
        firstName: inputValue.slice(0, spaceIndex),
        lastName: inputValue.slice(spaceIndex + 1),
      });
      this.updateProfileForUser({
        firstName: inputValue.slice(0, spaceIndex),
        lastName: inputValue.slice(spaceIndex + 1),
      });
    } else {
      this.setState({
        firstName: inputValue,
        lastName: "",
      });
      this.updateProfileForUser({
        firstName: inputValue,
        lastName: "",
      });
    }
  }
  async handleUpdatPosition(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });

    const body = {
      [name]: value,
    };
    this.updateProfileForUser({
      [name]: value,
    });
  }

  async getPosition() {
    const { isSuccess, data, error } = await apiCall(
      config.BASE_URL + "getTitleOrPositions",
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      this.setState({
        titleOrPositionsItems: data.titleOrPositions,
      });
    }
  }

  async componentDidMount() {
    const userId = Number(localStorage.getItem("user_temp_id"));
    const { isSuccess, data, error } = await apiCall(
      `${
        config.POSTLOGIN_API_URL_COMPANY
      }getProfileData?userId=${userId}&role_id=${
        ROLE_ID_MAPPING[localStorage.getItem("role")]
      }`
    );
    let beforeThis = data?.user?.profile_picture?.split(":")[0];
    if (isSuccess) {
      this.setState({
        isLoaded2: true,
        id: data.user?.id,
        firstName: data.user?.first_name,
        lastName: data.user?.last_name,
        email: data.user?.email,
        location: data.user?.Location,
        country: data.user?.country,
        businessNumber: data.user?.business_number,
        sectorofInterests: data.user?.sectorofInterests,
        userCategory: data.user?.user_category,
        register_company_name: data.user?.register_company_name,
        main_country_of_operations: data.user?.country,
        company_industry: data.user?.company_industry,
        logo:
          data.user?.profile_picture === null && beforeThis != "https"
            ? ProfilePics
            : data.user?.profile_picture,
        position: data.user?.position,
        no_of_users: data.user?.no_of_users,
      });
    } else {
      this.setState({
        isLoaded2: true,
        error,
      });
    }

    this.getPosition();
    const settingsMenu = JSON.parse(localStorage.getItem("menu"));
    const settingsObject = settingsMenu.find(
      (item) => item.caption === "Settings"
    );
    const settingsSubMenu = settingsObject ? settingsObject.sub_menu : [];
    const list = settingsSubMenu.find(
      (item) => item.caption === "Profile Management"
    )?.permissions;
    this.setState({
      permissionMenu: list,
    });
    console.log(list, "listlistlist");
  }

  showHide = (password) => {
    if (password === "password") {
      this.setState((prevState) => ({
        type: prevState.type === "input" ? "password" : "input",
      }));
    } else {
      this.setState((prevState) => ({
        type1: prevState.type1 === "input" ? "password" : "input",
      }));
    }
  };
  handleEditClick() {
    // Toggle the edit mode
    console.log(this.state.editMode);
    this.setState((prevState) => ({
      editMode: !prevState.editMode,
    }));
  }
  render() {
    const {
      firstName,
      lastName,
      businessNumber,
      register_company_name,
      company_industry,
      position,
      location,
      id,
      permissionMenu,
    } = this.state;

    return (
      <div className="main-body">
        <div className="directly">
          {permissionMenu.some(
            (permission) =>
              permission.permissionCode === "CHANGE_PASSWORD" &&
              permission.checked
          ) && (
            <Button variant="info" onClick={this.handleChangePasswordShow}>
              Change Password
            </Button>
          )}
        </div>
        <Row>
          <Col md={4}>
            <div className="card">
              <div className="card-body">
                <div className="d-flex flex-column align-items-center text-center">
                  <div className="upload_image">
                    <img
                      className="file-upload-image"
                      src={
                        this.state.logo || (
                          <Skeleton
                            circle
                            height="100%"
                            containerClassName="avatar-skeleton"
                          />
                        )
                      }
                      alt="your image"
                    />
                    <input
                      type="file"
                      name="uploadImage"
                      data-id={id}
                      onChange={
                        permissionMenu.some(
                          (permission) =>
                            permission.permissionCode ===
                              "UPDATE_PROFILE_PICTURE" && permission.checked
                        ) && this.onFileChange
                      }
                      title="Change Image"
                    />
                  </div>

                  <div className="mt-3">
                    <h4 className="m-0 edit_section_box_detail">
                      <input
                        type="text"
                        style={{
                          border: "none",
                          textAlign: "center",
                          padding: "0",
                        }}
                        value={firstName + " " + lastName}
                        onChange={
                          permissionMenu.some(
                            (permission) =>
                              permission.permissionCode === "UPDATE_NAME" &&
                              permission.checked
                          ) && this.handleUpdateProfile
                        }
                        // readOnly={!editMode}
                      />
                      <i
                        className="fas fa-pen-square"
                        title="Edit Name"
                        onClick={this.handleEditClick}
                      ></i>
                    </h4>
                    <p className="text-secondary m-0 edit_section_box_detail">
                      <input
                        type="text"
                        style={{
                          border: "none",
                          textAlign: "center",
                          padding: "0",
                        }}
                        value={position}
                        readOnly
                      />
                      {/* <select
                        style={{
                          border: "none",
                          textAlign: "center",
                          padding: "0",
                        }}
                        className="form-control input-height"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        name="position"
                        value={position}
                        onChange={this.handleUpdatPosition}
                      >
                        <option value="" disabled>
                          Please Select Title or Position
                        </option>
                        {this.state.titleOrPositionsItems.map((item, key) => (
                          <option key={key} value={item.title}>
                            {item.title}
                          </option>
                        ))}
                      </select>
                      <i class="fas fa-pen-square" title="Edit Designation"></i> */}
                    </p>
                    <p className="font-size-sm">{this.state.email}</p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col md={8}>
            <div className="card">
              <div className="card-body">
                <form name="form">
                  <Row className="align-items-center">
                    <Col md={6} className="text-secondary mb-3">
                      <label htmlFor="exampleInputEmail1">Company Name</label>
                      <input
                        type="text"
                        className="form-control input-height"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Company Name"
                        name="register_company_name"
                        value={register_company_name}
                        readOnly
                      />
                    </Col>
                    <Col md={6} className="text-secondary mb-3">
                      <label htmlFor="exampleInputEmail1">Country</label>
                      <input
                        type="text"
                        className="form-control input-height"
                        aria-describedby="emailHelp"
                        style={{ overflow: "auto" }}
                        name="main_country_of_operations"
                        value={"India"}
                        readOnly
                      />

                      {/* {location && location.map((location, index) => (
                        <input
                          key={index}
                          type="text"
                          className="form-control input-height"
                          id={`locationInput${index}`}
                          aria-describedby="emailHelp"
                          style={{ overflow: "auto" }}
                          name="main_country_of_operations"
                          value={JSON.parse(location.location).area + ", " + JSON.parse(location.location).city + ", " + JSON.parse(location.location).state + ", " + JSON.parse(location.location).country + ", " + JSON.parse(location.location).zipCode}
                          readOnly
                        />
                      ))} */}
                    </Col>
                    <Col md={6} className="text-secondary mb-3">
                      <label htmlFor="exampleInputPassword1">
                        Business Number
                      </label>
                      <input
                        type="tel"
                        className="form-control input-height"
                        id="exampleInputPassword1"
                        placeholder="businessNumber"
                        maxLength={9}
                        name="businessNumber"
                        value={businessNumber}
                        readOnly
                      />
                    </Col>
                    <Col md={6} className="text-secondary mb-3">
                      <label htmlFor="exampleInputPassword1">
                        Company Industry
                      </label>
                      <input
                        type="text"
                        className="form-control input-height"
                        id="exampleInputPassword1"
                        aria-describedby="emailHelp"
                        name="company_industry"
                        value={company_industry}
                        readOnly
                      />
                    </Col>
                    {location &&
                      location.map((location, index) => (
                        <Col md={12}>
                          {console.log("dsfvdsc", location)}
                          <Table striped bordered className="m-0">
                            <thead>
                              <tr style={{ background: "#ccc" }}>
                                <th>Office Address</th>
                              </tr>
                            </thead>
                          </Table>
                          <div className="access__section">
                            <Accordion>
                              <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                  {location?.company_name}
                                </Accordion.Header>
                                <Accordion.Body>
                                  <div class="border_box">
                                    {JSON.parse(location.location).area +
                                      ", " +
                                      JSON.parse(location.location).city +
                                      ", " +
                                      JSON.parse(location.location).state +
                                      ", " +
                                      JSON.parse(location.location).country +
                                      ", " +
                                      JSON.parse(location.location).zipCode}
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </div>
                        </Col>
                      ))}
                  </Row>
                </form>
              </div>
            </div>
          </Col>
        </Row>
        <Modal
          show={this.state.showChangePasswordPopup}
          onHide={this.handleChangePasswordClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Reset Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col
                md={12}
                className="text-secondary mb-3"
                style={{ position: "relative" }}
              >
                <div className="form-group fg eye-frame">
                  <label className="st_name" htmlFor="name">
                    Password
                  </label>
                  <input
                    type={this.state.type}
                    className="form-control name_nf"
                    id="exampleInputPassword1"
                    placeholder="Enter password for Change"
                    name="password"
                    value={this.state.password}
                    required
                    onChange={(e) => this.handleConfirmPassword(e, "password")}
                    // onChange={(e) =>
                    //   this.setState({ password: e.target.value })
                    // }
                  />
                  <div className="eye-under">
                    <span onClick={() => this.showHide("password")}>
                      {this.state.type === "input" ? (
                        <i className="fas fa-eye-slash"></i>
                      ) : (
                        <i className="fas fa-eye"></i>
                      )}
                    </span>
                  </div>
                </div>
                <div className="form-group fg eye-frame">
                  <label className="st_name" htmlFor="name">
                    Confirm Password
                  </label>
                  <input
                    className="form-control name_nf"
                    type={this.state.type1}
                    required
                    name="confirmPassword"
                    placeholder="Enter Confirm Password"
                    value={this.state.confirmPassword}
                    onChange={(e) =>
                      this.handleConfirmPassword(e, "conPassword")
                    }
                  />
                  {this.state.confirmPassword &&
                    (this.state.passwordMatch ? (
                      <div className="green" mt-2>
                        Password Matched
                      </div>
                    ) : (
                      <div className="red mt-2">
                        Confirm Password is Matched
                      </div>
                    ))}
                  <div className="eye-under">
                    <span onClick={() => this.showHide("confirmPassword")}>
                      {this.state.type1 === "input" ? (
                        <i className="fas fa-eye-slash"></i>
                      ) : (
                        <i className="fas fa-eye"></i>
                      )}
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-danger"
              onClick={this.handleChangePasswordClose}
            >
              Cancel
            </Button>
            {this.state?.loading ? (
              <Button variant="info"  disabled>
                <Spinner animation="border" /> Updating...
              </Button>
            ) : (
              <Button
                type="submit"
                variant="info"
                disabled={!this.state.passwordMatch}
                onClick={this.handlePasswordChange}
              >
                <b>Update Password</b>
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
