import React from 'react';
import ReactApexChart from 'react-apexcharts';

const RangeAreaChart = () => {
  const chartData = {
    series: [
      {
        type: 'rangeArea',
        name: 'Mumbai Range',
        data: [
          { x: 'Jan', y: [10, 35] },
          { x: 'Feb', y: [12, 18] },
          { x: 'Mar', y: [9, 29] },
          { x: 'Apr', y: [14, 27] },
          { x: 'May', y: [26, 39] },
          { x: 'Jun', y: [5, 17] },
          { x: 'Jul', y: [19, 23] },
          { x: 'Aug', y: [10, 15] },
        ],
      },
      {
        type: 'rangeArea',
        name: 'Bangalore Range',
        data: [
          { x: 'Jan', y: [31, 34] },
          { x: 'Feb', y: [42, 52] },
          { x: 'Mar', y: [39, 49] },
          { x: 'Apr', y: [34, 39] },
          { x: 'May', y: [51, 59] },
          { x: 'Jun', y: [54, 67] },
          { x: 'Jul', y: [43, 46] },
          { x: 'Aug', y: [21, 29] },
        ],
      },
      {
        type: 'line',
        name: 'Mumbai Median',
        data: [
          { x: 'Jan', y: 15 },
          { x: 'Feb', y: 17 },
          { x: 'Mar', y: 19 },
          { x: 'Apr', y: 22 },
          { x: 'May', y: 30 },
          { x: 'Jun', y: 10 },
          { x: 'Jul', y: 21 },
          { x: 'Aug', y: 12 },
          { x: 'Sep', y: 18 },
          { x: 'Oct', y: 20 },
        ],
      },
      {
        type: 'line',
        name: 'Bangalore Median',
        data: [
          { x: 'Jan', y: 33 },
          { x: 'Feb', y: 49 },
          { x: 'Mar', y: 43 },
          { x: 'Apr', y: 37 },
          { x: 'May', y: 55 },
          { x: 'Jun', y: 59 },
          { x: 'Jul', y: 45 },
          { x: 'Aug', y: 24 },
          { x: 'Sep', y: 21 },
          { x: 'Oct', y: 15 },
        ],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: 'rangeArea',
        animations: {
          speed: 500,
        },
      },
      colors: ['#d4526e', '#33b2df', '#d4526e', '#33b2df'],
      dataLabels: {
        enabled: false,
      },
      fill: {
        opacity: [0.24, 0.24, 1, 1],
      },
      forecastDataPoints: {
        count: 2,
      },
      stroke: {
        curve: 'straight',
        width: [0, 0, 2, 2],
      },
      legend: {
        show: true,
        customLegendItems: ['Mumbai', 'Bangalore'],
        inverseOrder: true,
      },
      // title: {
      //   text: 'Range Area View',
      // },
      markers: {
        hover: {
          sizeOffset: 5,
        },
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            return val.toFixed(2) + '%'; // Display values as percentages in decimal form
          },
        },
        max: 100,
        min: 0,
        tickAmount: 5, // Adjust the number of ticks to achieve a 20% gap
      },
    },
  };

  return (
    <div id="chart">
      <ReactApexChart options={chartData.options} series={chartData.series} type="rangeArea" height={200} />
    </div>
  );
};

export default RangeAreaChart;
