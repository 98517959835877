import React, { useEffect, useState } from "react";
import Sidebar from "../../../sidebar/sidebar";
import Header from "../../../header/header";
import { Col, Form, Modal, ProgressBar, Row, Table } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import SummeryGraph from "./DashboardGraph/SummeryGraph";
import MyDisclosureProgressComponent from "../home/MyDisclosureProgressComponent";
import FrameworkComponent from "../home/FrameworkComponent";
import SubAdminComponent from "../home/SubAdminComponent";
import FocusAreaComponent from "../home/FocusAreaComponent";
import QuestionTypeComponent from "../home/QuestionTypeComponent";
import Multiselect from "multiselect-react-dropdown";
import { apiCall } from "../../../../_services/apiCall";
import config from "../../../../config/config.json";
import NoNotification from "../../../../img/no-results.png"
const Dashboard = (props) => {
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const firstName = currentUser?.first_name;
  const lastName = currentUser?.last_name;
  const handleClose = () => setShow(false);
  const [show, setShow] = useState(false);
  const [questionCatqoryList, setQuestionCatqoryList] = useState([]);
  const [questionScopesList, setQuestionScopesList] = useState([]);
  const [permissionList, setPermissionList] = useState([]);
  const [lastWeekAcitivities, setLastWeekAcitivities] = useState();
  const [todaysActivities, setTodaysActivities] = useState([]);
  const [teamWorkloadData, setTeamWorkloadData] = useState([]);
  const lastWeekActivity = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}lastWeekActivity`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      setLastWeekAcitivities(data?.data);
    }
  };

  const todaysActivity = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}todaysActivity`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      setTodaysActivities((data?.data).reverse());
    }
  };

  const teamWorkloadProgess = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}teamWorkloadProgess`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      setTeamWorkloadData(data?.data?.teamWorkloadResults);
    }
  };

  const questionCatqoryProgress = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}questionCatqoryProgress`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      setQuestionCatqoryList(data?.data);
    }
  };

  const getSectorQuestionScopes = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getSectorQuestionScopes`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      setQuestionScopesList(data?.data);
    }
  };

  const handleApplyFilters = async (graphType, filter, type) => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}saveGraphFilter`,
      {},
      { graphName: graphType, filter: JSON.stringify(filter) },
      "POST"
    );
    if (isSuccess) {
      if (type === "SCOPE") {
        getSectorQuestionScopes();
      } else if (type === "CATEGORIES") {
        questionCatqoryProgress();
      }
    }
  };

  const onSelect = (data) => {
    localStorage.setItem("questionIds", data);
    window.location.href = "/#/sector_questions";
  };

  useEffect(() => {
    const dashboardMenu = JSON.parse(localStorage.getItem("menu"));
    const dashboardObject = dashboardMenu.find(
      (item) => item.caption === "Dashboard"
    ).permissions;
    setPermissionList(dashboardObject);
    lastWeekActivity();
    todaysActivity();
    teamWorkloadProgess();
    questionCatqoryProgress();
    getSectorQuestionScopes();
  }, []);
  return (
    <div>
      <Sidebar dataFromParent={props.location.pathname} />
      <Header />
      <div className="main_wrapper">
        <div className="inner_wraapper p-3">
          <div className="text-center mb-4">
            <h3>
              <b>{lastWeekAcitivities?.message}</b>
            </h3>
            <p>
              Here's where you'll view a summary of RIU - Frontend's status,
              priorities, workload, and more.
            </p>
          </div>
          <Row>
            <Col
              xl={3}
              lg={3}
              md={6}
              className="mb-3"
              onClick={() => onSelect(lastWeekAcitivities?.done?.questionId)}
            >
              <div className="bg-white widget-style1">
                <div className="d-flex flex-wrap align-items-center done">
                  <div className="circle-icon">
                    <div className="icon">
                      <i className="fa fa-check" aria-hidden="true"></i>
                    </div>
                  </div>
                  <div className="widget-data">
                    <h4>
                      <b>{lastWeekAcitivities?.done?.number} Done</b>
                    </h4>
                    <p>In the Last 7 Days</p>
                  </div>
                </div>
              </div>
            </Col>

            <Col
              xl={3}
              lg={3}
              md={6}
              className="mb-3"
              onClick={() => onSelect(lastWeekAcitivities?.updated?.questionId)}
            >
              <div className="bg-white widget-style1">
                <div className="d-flex flex-wrap align-items-center update">
                  <div className="circle-icon">
                    <div className="icon">
                      <i className="fa fa-pen" aria-hidden="true"></i>
                    </div>
                  </div>
                  <div className="widget-data">
                    <h4>
                      <b>{lastWeekAcitivities?.updated?.number} Updated</b>
                    </h4>
                    <p>In the Last 7 Days</p>
                  </div>
                </div>
              </div>
            </Col>
            <Col
              xl={3}
              lg={3}
              md={6}
              className="mb-3"
              onClick={() => onSelect(lastWeekAcitivities?.pending?.questionId)}
            >
              <div className="bg-white widget-style1">
                <div className="d-flex flex-wrap align-items-center create">
                  <div className="circle-icon">
                    <div className="icon">
                      <i className="fa fa-plus" aria-hidden="true"></i>
                    </div>
                  </div>
                  <div className="widget-data">
                    <h4>
                      <b>{lastWeekAcitivities?.pending?.number} Pending</b>
                    </h4>
                    <p>In the Last 7 Days</p>
                  </div>
                </div>
              </div>
            </Col>
            <Col
              xl={3}
              lg={3}
              md={6}
              className="mb-3"
              onClick={() => onSelect(lastWeekAcitivities?.due?.questionId)}
            >
              <div className="bg-white widget-style1">
                <div className="d-flex flex-wrap align-items-center due">
                  <div className="circle-icon">
                    <div className="icon">
                      <i className="fas fa-calendar-alt" aria-hidden="true"></i>
                    </div>
                  </div>
                  <div className="widget-data">
                    <h4>
                      <b>{lastWeekAcitivities?.due?.number} Due</b>
                    </h4>
                    <p>In the Next 7 Days</p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            {permissionList.some(
              (permission) =>
                permission.permissionCode === "STATUS_OVERVIEW" &&
                permission.checked
            ) && (
              <Col xl={6} lg={6} md={12} className="mb-3">
                <div
                  className="bg-white widget-style1 summery__widget"
                  style={{ position: "relative" }}
                >
                  <h5 className="p-0">
                    <b>Status overview</b>
                  </h5>
                  <p>Get a snapshot of the status of your items. </p>
                  <SummeryGraph />
                </div>
              </Col>
            )}
            {permissionList.some(
              (permission) =>
                permission.permissionCode === "RECENT_ACTIVITY" &&
                permission.checked
            ) && (
              <Col xl={6} lg={6} md={12} className="mb-3">
                <div className="bg-white widget-style1 summery__widget">
                  <h5 className="p-0">
                    <b>Recent Activity</b>
                  </h5>
                  <p>
                    Stay up to date with what's happening across the project.
                  </p>
                  <div className="activity_section">
                    <h5 className="p-0">Today</h5>
                    {todaysActivities.length > 0 ? (
                      todaysActivities.map((notification) => (
                        <div
                          key={notification.id}
                          className="d-flex flex-wrap mb-2"
                        >
                          <div className="circle-icon">
                            <div className="name_icon">
                              {firstName.charAt(0)}
                            </div>
                          </div>
                          <div className="widget-data">
                            <p className="m-0">
                              {`${firstName} ${lastName}`}{" "}
                              <NavLink
                                to={{
                                  pathname: "/sector_questions",
                                  state: {
                                    questionIds: [notification?.questionId],
                                  },
                                }}
                              >
                                <span title={notification?.massage}>
                                  {notification?.massage.length > 30
                                    ? `${notification?.massage.slice(
                                        0,
                                        68
                                      )}.....`
                                    : notification?.massage}
                                </span>
                              </NavLink>{" "}
                              {notification?.status}
                            </p>

                            <span>
                              {new Date(
                                notification.createdAt
                              ).toLocaleString()}
                            </span>
                          </div>
                        </div>
                      ))
                    ) : (
                     <div className="text-center w-100 h-75"> <img src={NoNotification} alt="No Notification Here..." className="w-50 h-100"/></div>
                    )}
                  </div>
                </div>
              </Col>
            )}
          </Row>
          <Row>
            {permissionList.some(
              (permission) =>
                permission.permissionCode === "TEAM_WORKLOAD" &&
                permission.checked
            ) && (
              <Col xl={6} lg={6} md={12} className="mb-3">
                <div className="bg-white widget-style1">
                  <h5 className="p-0">
                    <b>Team Workload</b>
                  </h5>
                  <p>Oversee the capacity of your team.</p>
                  <div className="activity_section">
                    <Table>
                      <thead>
                        <th style={{ width: 200 }}>Assignee</th>
                        <th>Work Distribution</th>
                        <th>Count</th>
                      </thead>
                      <tbody>
                        {teamWorkloadData.length > 0 ? (
                          teamWorkloadData.map((data) => {
                            return (
                              <tr key={data.userId}>
                                <td>
                                  <div className="hstack">
                                    <div className="circle-icon">
                                      <div
                                        className="name_icon m-0"
                                        title={data.email}
                                      >
                                        {data?.firstName.charAt(0) +
                                          data?.lastName.charAt(0)}
                                      </div>
                                    </div>
                                    <div className="widget-data">
                                      <p className="m-0" title={data.email}>
                                        {`${data.firstName} ${data.lastName}`}
                                      </p>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <ProgressBar
                                    variant="warning"
                                    now={
                                      isNaN(data?.percentageAccepted)
                                        ? 0
                                        : data?.percentageAccepted
                                    }
                                    label={`${
                                      isNaN(data?.percentageAccepted)
                                        ? "0"
                                        : data?.percentageAccepted
                                    }%`}
                                  />
                                </td>
                                <td>
                                  <NavLink to={"/"}>
                                    {data?.totalQuestions}
                                  </NavLink>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <p>No Data Found</p>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Col>
            )}
            {permissionList.some(
              (permission) =>
                permission.permissionCode === "MY_DISCLOSURE_PROGRESS" &&
                permission.checked
            ) && (
              <Col xl={6} lg={6} md={12} className="mb-3">
                <div className="bg-white widget-style1 p-0 summery__widget">
                  <MyDisclosureProgressComponent />
                </div>
              </Col>
            )}
          </Row>
          <div className="color_div_Current mb-3">
            <div className="d-flex align-items-center justify-content-between">
              <div className="color_rent mb-0">
                <h6 className="home_text">Download Your Full ESG Report</h6>
              </div>
              <button
                onClick={() => {
                  setShow(true);
                }}
                className="new_button_style"
              >
                Download
              </button>
            </div>
          </div>
          <Row>
            {permissionList.some(
              (permission) =>
                permission.permissionCode === "FRAMEWORK_PROGRESS" &&
                permission.checked
            ) && (
              <Col xl={6} lg={6} md={12} className="mb-3">
                <div className="bg-white widget-style1 p-0 summery__widget">
                  <FrameworkComponent />
                </div>
              </Col>
            )}
            {permissionList.some(
              (permission) =>
                permission.permissionCode === "USER_PROGRESS" &&
                permission.checked
            ) && (
              <Col xl={6} lg={6} md={12} className="mb-3">
                <div className="bg-white widget-style1 p-0 summery__widget">
                  <SubAdminComponent />
                </div>
              </Col>
            )}
         
            {permissionList.some(
              (permission) =>
                permission.permissionCode === "SOURCE_PROGRESS" &&
                permission.checked
            ) && (
              <Col xl={6} lg={6} md={12} className="mb-3">
                <div className="bg-white widget-style1 p-0 summery__widget">
                  <FocusAreaComponent />
                </div>
              </Col>
            )}

            {permissionList.some(
              (permission) =>
                permission.permissionCode === "QUESTION_TYPE_PROGRESS" &&
                permission.checked
            ) &&
              questionCatqoryList?.length > 0 &&
              questionCatqoryList.map((item, index) =>
                !Object.keys(item).length ? null : (
                  <Col key={index} xl={6} lg={6} md={12} className="mb-3">
                    <div className="bg-white widget-style1 p-0 summery__widget">
                      {Object.keys(item).map((key) =>
                        item[key] &&
                        item[key].hasOwnProperty("series") &&
                        item[key].hasOwnProperty("categories") ? (
                          <QuestionTypeComponent
                            key={key}
                            category={key}
                            data={item[key]}
                            handleApplyFilters={handleApplyFilters}
                            type="CATEGORIES"
                            enable={false}
                          />
                        ) : null
                      )}
                    </div>
                  </Col>
                )
              )}
            {permissionList.some(
              (permission) =>
                permission.permissionCode === "QUESTION_TYPE_PROGRESS" &&
                permission.checked
            ) &&
              questionScopesList?.length > 0 &&
              questionScopesList.map((item, index) =>
                !Object.keys(item).length ? null : (
                  <Col key={index} xl={6} lg={6} md={12} className="mb-3">
                    <div className="bg-white widget-style1 p-0 summery__widget">
                      {Object.keys(item).map((key) =>
                        item[key] &&
                        item[key].hasOwnProperty("series") &&
                        item[key].hasOwnProperty("categories") ? (
                          <QuestionTypeComponent
                            key={key}
                            category={key}
                            data={item[key]}
                            handleApplyFilters={handleApplyFilters}
                            type="SCOPE"
                            enable={true}
                            total={true}
                          />
                        ) : null
                      )}
                    </div>
                  </Col>
                )
              )}
          </Row>
        </div>
      </div>
      <Modal size="md" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <label className="align-items-center"> ESG REPORT </label>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Select Financial Year</Form.Label>
                <Form.Select aria-label="Default select example">
                  <option>--select--</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Select Framework</Form.Label>
                <Multiselect
                  isObject={false}
                  onKeyPressFn={function noRefCheck() {}}
                  onRemove={function noRefCheck() {}}
                  onSearch={function noRefCheck() {}}
                  onSelect={function noRefCheck() {}}
                  options={[
                    "Designmation 1",
                    "Designmation 1",
                    "Designmation 2",
                    "Designmation 3",
                    "Designmation 4",
                  ]}
                />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Select Report Type</Form.Label>
                <Form.Select aria-label="Default select example">
                  <option>--select--</option>
                  <option value="1">Custom</option>
                  <option value="2">Standard</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Select File Type</Form.Label>
                <Form.Select aria-label="Default select example">
                  <option>--select--</option>
                  <option value="1">Excel</option>
                  <option value="2">CSV</option>
                  <option value="3">PDF</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="new_button_style"
            onClick={() => {
              setShow(false);
            }}
          >
            Download
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Dashboard;
