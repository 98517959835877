import React, { useState } from "react";
import Header from "../../../header/header";
import Sidebar from "../../../sidebar/sidebar";
import { NavLink, useLocation } from "react-router-dom";
import { Accordion, Col, Row, Table } from "react-bootstrap";
import ToggleButton from "react-toggle-button";


const RegisterdSupplierManagement = () => {
  const location = useLocation();
  const [selectedRow, setSelectedRow] = useState(null);
  const [enable, setEnable] = useState();
  const handleToggle = () => {
    setEnable((prevValue) => !prevValue);
  };
  return (
    <>
      <Sidebar dataFromParent={location?.pathname} />
      <Header />
      <div className="main_wrapper">
        <section className="inner_wraapper">
          <div className="hol_rell">
            <div className="steps-form">
              <div className="steps-row setup-panel">
                <div className="tabs-top setting_admin my-0">
                  <ul>
                    <li>
                      <NavLink to="/supplier-management" className="activee"> Registerd Suppliers</NavLink>
                    </li>
                    <li>
                      <NavLink to="/invited-suppliers"> Invited Suppliers </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="Introduction">
            <Row>
              <Col md={7} style={{ borderRight: "1px solid #bdc1c9" }}>
                <div className="table_setting">
                  <Table striped bordered hover className="m-0">
                    <thead>
                      <tr
                        style={{ background: "#ccc" }}
                        className="fixed_tr_section"
                      >
                        <th style={{ width: 55 }}>S No.</th>
                        <th>Company Name</th>
                        <th>Business No.</th>
                        <th>City</th>
                        <th style={{ width: 55 }}>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <td className="text-center">1 </td>
                        <td>Galax</td>
                        <td>1234567890</td>
                        <td>Bengaluru</td>
                        <td>
                          <ToggleButton
                            value={enable}
                            onToggle={handleToggle}
                          >
                            {enable ? "Active" : "Inactive"}
                          </ToggleButton>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <div className="Page navigation example"></div>
                </div>
              </Col>
              <Col md={5}>
                <div className="table_setting">
                  <Table striped bordered hover className="m-0">
                    <thead>
                      <tr style={{ background: "#ccc" }}>
                        <th style={{ width: 100 }}>Attribute</th>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>First name</td>
                        <td>Sharique</td>
                      </tr>
                      <tr>
                        <td>Last Name</td>
                        <td>Zia</td>
                      </tr>
                      <tr>
                        <td>Email</td>
                        <td>sharique.zia@gmail.com</td>
                      </tr>
                      <tr>
                        <td>Designation</td>
                        <td>CEO</td>
                      </tr>
                      <tr>
                        <td>Phone No.</td>
                        <td>+91 1234567890</td>
                      </tr>
                      <tr style={{ background: "#ccc" }}>
                        <td colSpan={2}>
                          <b>Company Address</b>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <React.Fragment key="0">
                    <div className="access__section">
                      <Accordion defaultActiveKey="0">
                        <Accordion.Item >
                          <Accordion.Header>
                            Company Name
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="border_box">
                              Bengaluru
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </React.Fragment>
                  <div className="Page navigation example"></div>
                </div>
              </Col>
            </Row>
          </div>
        </section>
      </div>
    </>
  );
};

export default RegisterdSupplierManagement;
